$color-shipping-map-fill: $color-silver;
$color-shipping-map-fill-active: $color-verdungreen;
$color-shipping-map-stroke: $color-white;

.shipping-map {
  &-svg {
    max-width: 100%;
  }

  &-path {
    fill: $color-shipping-map-fill;
    stroke: $color-shipping-map-stroke;
    stroke-width: 2;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-opacity: 0.25;

    &-active {
      fill: $color-shipping-map-fill-active;
    }
  }

  &-legend {
    margin-top: 1rem;
  }

  &-legend-item {
    display: flex;
    align-items: center;

    &-box {
      height: 12px;
      width: 12px;
      margin-right: 6px;
      background-color: $color-shipping-map-fill;

      &-active {
        background-color: $color-shipping-map-fill-active;
      }
    }
  }

  &-list {
    margin-top: 1rem;
    &-item-qty {
      margin-left: 0.4rem;
    }
  }
}
