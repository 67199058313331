.shop {
  .ms-account-management-address {
    padding: 2rem;

    .msc-address-list {
      &__heading {
        @extend %text-shop-heading-secondary;
      }
      &__button-add {
        @extend %btn-default;
        @extend .btn-black;
      }
      &__primary {
        @include clearfix();
        margin-top: 2rem;
      }
      &__primary-heading {
        @extend %text-shop-heading-secondary;
        margin-bottom: 1rem;
        font-size: $font-size-body-l;
        font-weight: bold;
        text-transform: none;
      }
      &__button-edit,
      &__button-primary {
        @extend %link-shop;
        display: inline-block;
        margin-top: 0.5rem;
      }
      &__button-edit {
        float: left;
      }
      &__button-primary {
        margin-left: 1rem;
      }
    }
    .msc-address-form {
      &__alert {
        @extend %error-alert;
        padding: 0.5rem 0.75rem;
        font-size: 14px;
        line-height: 1.2;
        width: 100%;
        margin: 0.25rem 0 0.5rem;
        display: none;
      }
      &__warning {
        @extend %warning-alert;
        padding: 0.5rem 0.75rem;
        font-size: 14px;
        line-height: 1.2;
        width: 100%;
        margin: 0.25rem 0 0.5rem;
        display: none;
      }
      &__item {
        &.address-form__item-invalid {
          .msc-address-form__alert {
            display: block;
          }
        }
        .msc-address-form__warning {
          display: block;
        }
      }
    }
  }
}
