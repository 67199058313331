$search-result-container-nav-height: 20px;
$search-result-container-nav-margin-bottom: 20px;
$search-result-container-nav-margin-top: 32px;
$search-result-container-nav-title-margin: 0px;
$search-result-container-nav-font-size: 12px;

$search-result-container-refine-product-margin-bottom: 80px;

$search-result-container-refiner-section-padding-right: 40px;
$search-result-container-refiner-section-width: 25%;

$search-result-container-refiner-button-submenu-height: 40px;
$search-result-container-refiner-button-submenu-padding-left: 0px;
$search-result-container-refiner-button-submenu-padding-right: 0px;

$search-result-container-refiner-submenu-list-group-border-color: #ccc;
$search-result-container-refiner-submenu-list-group-margin-bottom: 0px;
$search-result-container-refiner-submenu-list-group-margin-left: 0px;

$search-result-container-refiner-submenu-item-label-padding-left: 12px;

$search-result-container-refiner-submenu-list-group-item-padding-left: 0px;
$search-result-container-refiner-list-group-item-border: 1px solid rgba(0, 0, 0, 0);
$search-result-container-refiner-list-group-item-outline-offset: 2px;
$search-result-container-refiner-list-group-item-padding: 21px 12px 0px 12px;

$search-result-container-refiner-slider-horizontal-background-color: #ccc;
$search-result-container-refiner-slider-horizontal-height: 4px;

$search-result-container-refiner-slider-padding: 0px;
$search-result-container-refiner-slider-track-background-color: $color-link-primary;
$search-result-container-refiner-slider--track-top: 0px;

$search-result-container-refiner-slider-thumb-background: $color-link-primary;
$search-result-container-refiner-slider-thumb-background-size: 12px 12px;
$search-result-container-refiner-slider-thumb-border-radius: 6px;
$search-result-container-refiner-slider-thumb-height: 12px;
$search-result-container-refiner-slider-thumb-outline-offset: 2px;
$search-result-container-refiner-slider-thumb-top: 50%;
$search-result-container-refiner-slider-thumb-width: 12px;

$search-result-container-refiner-slider-thumb-before-bottom: 0px;
$search-result-container-refiner-slider-thumb-before-left: 0px;
$search-result-container-refiner-slider-thumb-before-right: 0px;
$search-result-container-refiner-slider-thumb-before-top: 0px;

$search-result-container-refiner-slider-labels-item-font-size: 14px;
$search-result-container-refiner-slider-labels-item-margin-top: 25px;
$search-result-container-refiner-slider-labels-item-padding-bottom: 20px;

$search-result-container-pagination-padding-left: 0px;

$search-result-container-product-section-width: 100%;
$search-result-container-sort-by-category-padding-left: 8px;
$search-result-container-sort-by-category-right: 0px;
$search-result-container-sort-by-category-dropdown-label-margin-right: 5px;
$search-result-container-sort-by-category-dropdown-height: 32px;

$search-result-container-products-margin-bottom: 80px;
$search-result-container-products-margin-top: 32px;

$search-result-container-product-search-result-item-grid-column-gap: 1px;
$search-result-container-product-search-result-item-width: 249px;
$search-result-container-product-search-result-item-padding: 0 2px 0 0;
$search-result-container-product-search-item-title-margin-bottom: 4px;
$search-result-container-product-search-item-title-margin-top: 4px;
$search-result-container-product-search-item-rating-margin-top: 4px;

$search-result-container-page-item-previous-padding-right: 10px;
$search-result-container-page-item-previous-padding-left: 4px;
$search-result-container-page-item-padding-right: 10px;
$search-result-container-page-item-next-padding-right: 4px;

$search-result-container-margin-top-margin-top-mobile: 24px;

$search-result-container-pagination-margin-bottom-mobile: 16px;
$search-result-container-products-margin-bottom-mobile: 40px;

$search-result-container-choice-summary-padding-inline-start: 0px;
$search-result-container-choice-summary-margin-left: 8px;
$search-result-container-choice-summary-margin-top: 32px;

$search-result-container-choice-summary-background-color: #ccc;
$search-result-container-choice-summary-color: $color-link-primary;
$search-result-container-choice-summary-border: 1px #ccc solid;
$search-result-container-choice-summary-margin-right: 12px;
$search-result-container-choice-summary-border-radius: 3px;
$search-result-container-choice-summary-padding-left: 8px;
$search-result-container-choice-summary-outline-offset: 2px;

$search-result-container-choice-summary-glypg-margin-left: 8px;
$search-result-container-choice-summary-glypg-margin-right: 8px;

$search-result-modal-header-close-button-padding: 20px;
$search-result-modal-header-close-button-margin: -20px -20px -20px auto;

$search-result-modal-body-height: 450px;
$search-result-modal-body-padding: 40px 60px 10px 60px;

$search-result-modal-footer-button-color: $color-white;
$search-result-modal-footer-button-background-color: $color-link-primary;
$search-result-modal-footer-button-border-color: $color-link-primary;
$search-result-modal-footer-button-padding: 12px 20px;
$search-result-modal-footer-button-min-width: 160px;
$search-result-container-refiner-submenu-list-group-border-width: 1px;

$search-result-modal-refiner-section-padding-right: 0px;

$search-result-container-padding-left-mobile: 16px;
$search-result-container-padding-right-mobile: 16px;

$search-result-container-sort-by-category-top-mobile: 0px;
$product-search-result_link__item-outline-width: 1px;
$product-search-result_link__item-outline-style: dashed;
$product-search-result_link__item-outline-offset: 2px;

$search-result-prev-next-padding: 0 4px;

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.ms-search-result-container {
  &__title {
    text-transform: uppercase;
    span {
      color: $color-codgray;
    }
  }
  .list-unstyled {
    display: grid;
    grid-template-columns: 100%;
    @media (min-width: 840px) {
      grid-template-columns: 50% 50%;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 33.33% 33.33% 33.33%;
    }
  }
}
.ms-search-result-container__category-nav-section {
  display: flex;
  height: $search-result-container-nav-height;
  justify-content: flex-end;
  margin-bottom: $search-result-container-nav-margin-bottom;
  margin-top: $search-result-container-nav-margin-top;

  .ms-search-result-container__category-hierarchy {
    font-size: $search-result-container-nav-font-size;
  }

  .ms-search-result-container__title {
    h5,
    .h5 {
      margin: $search-result-container-nav-title-margin;

      .ms-search-result__collection-title-count {
        font-size: $search-result-container-nav-font-size;
        font-weight: normal;
      }
    }
  }
}

.ms-search-result-container__refine-product-section {
  // display: inline-flex;
  // margin-bottom: $search-result-container-refine-product-margin-bottom;
  // width: 100%;
  margin: 0 auto 2rem;
  max-width: 1025px;
  padding: 0rem;
  background-color: #fff;
  flex: 1 0 auto;
}

// refiner section
.ms-search-result-container__refiner-section {
  display: block;
  padding-right: $search-result-container-refiner-section-padding-right;
  width: $search-result-container-refiner-section-width;

  .collapse:not(.show) {
    display: none;
  }

  button.ms-refine-submenu__toggle_collapsed,
  .ms-refine-submenu__toggle_expanded {
    background: transparent;
    display: flex;
    height: $search-result-container-refiner-button-submenu-height;
    justify-content: space-between;
    padding-left: $search-result-container-refiner-button-submenu-padding-left;
    padding-right: $search-result-container-refiner-button-submenu-padding-right;
    width: 100%;
    margin-top: 2px;
  }

  .ms-refine-submenu.list-group {
    border-bottom-style: solid;
    border-bottom-width: $search-result-container-refiner-submenu-list-group-border-width;
    border-color: $search-result-container-refiner-submenu-list-group-border-color;
    display: flex;
    flex-direction: column;
    margin-bottom: $search-result-container-refiner-submenu-list-group-margin-bottom;
    padding-left: $search-result-container-refiner-submenu-list-group-margin-left;

    &:first-child {
      border-top-style: solid;
      border-top-width: $search-result-container-refiner-submenu-list-group-border-width;
      border-color: $search-result-container-refiner-submenu-list-group-border-color;
    }

    .ms-refine-submenu-item {
      padding-left: $search-result-container-refiner-submenu-list-group-item-padding-left;
      margin-bottom: 2px;

      > a {
        @include vfi();
      }

      .msc-rating {
        display: inline-block;
      }
    }

    .multi-select,
    .multi-select-checked,
    .single-select,
    .single-select-checked {
      @include vfi();
      display: block;
      border: 1px solid transparent;

      span.ms-refine-submenu-item__label {
        padding-left: $search-result-container-refiner-submenu-item-label-padding-left;
        vertical-align: top;
      }
    }
  }

  .list-group-item {
    border: $search-result-container-refiner-list-group-item-border;
    display: block;
    outline-offset: $search-result-container-refiner-list-group-item-outline-offset;
    padding: $search-result-container-refiner-list-group-item-padding;
    position: relative;
  }

  .slider-horizontal {
    background-color: $search-result-container-refiner-slider-horizontal-background-color;
    height: $search-result-container-refiner-slider-horizontal-height;
  }

  .slider {
    box-shadow: inset 0 0 2px;
    cursor: pointer;
    display: block;
    padding: $search-result-container-refiner-slider-padding;
    position: relative;
    touch-action: none;

    .slider__track {
      background-color: $search-result-container-refiner-slider-track-background-color;
      height: 100%;
      position: absolute;
      top: $search-result-container-refiner-slider--track-top;
    }

    .slider__thumb {
      @include vfi();
      background: $search-result-container-refiner-slider-thumb-background;
      background-size: $search-result-container-refiner-slider-thumb-background-size;
      border-radius: $search-result-container-refiner-slider-thumb-border-radius;
      display: inline-block;
      height: $search-result-container-refiner-slider-thumb-height;
      outline-offset: $search-result-container-refiner-slider-thumb-outline-offset;
      position: absolute;
      top: $search-result-container-refiner-slider-thumb-top;
      transform: translate3d(-50%, -50%, 0);
      width: $search-result-container-refiner-slider-thumb-width;
    }

    .slider__thumb:before {
      bottom: $search-result-container-refiner-slider-thumb-before-bottom;
      content: "";
      display: block;
      left: $search-result-container-refiner-slider-thumb-before-left;
      outline-offset: calc(0px + 0.0625rem);
      position: absolute;
      right: $search-result-container-refiner-slider-thumb-before-right;
      top: $search-result-container-refiner-slider-thumb-before-top;
    }

    .slider__labels-item {
      cursor: pointer;
      display: inline-block;
      font-size: $search-result-container-refiner-slider-labels-item-font-size;
      margin-top: $search-result-container-refiner-slider-labels-item-margin-top;
      padding-bottom: $search-result-container-refiner-slider-labels-item-padding-bottom;
    }

    .slider__labels-item.start {
      float: left;
    }

    .slider__labels-item.end {
      float: right;
    }
  }
}

// product section
.ms-search-result-container__product-section {
  width: $search-result-container-product-section-width;
  .category-description {
    margin-bottom: 1rem;
    color: $color-dovegray;
    font-size: 13px;
    line-height: 1.4;
  }
}

.msc-choice-summary {
  list-style-type: none;
  padding-inline-start: $search-result-container-choice-summary-padding-inline-start;
  margin-left: $search-result-container-choice-summary-margin-left;
  margin-top: $search-result-container-choice-summary-margin-top;

  .msc-choice-summary__list {
    display: inline-block;
  }

  .msc-choice-summary__list-item {
    display: inline-block;
  }

  .msc-choice-summary__item {
    @include vfi();
    background-color: $search-result-container-choice-summary-background-color;
    color: $search-result-container-choice-summary-color;
    border: $search-result-container-choice-summary-border;
    margin-right: $search-result-container-choice-summary-margin-right;
    border-radius: $search-result-container-choice-summary-border-radius;
    padding-left: $search-result-container-choice-summary-padding-left;
    outline-offset: $search-result-container-choice-summary-outline-offset;
    position: relative;
  }

  .msc-choice-summary__clear-all {
    @include vfi();
  }

  .msc-choice-summary__glyph {
    margin-left: $search-result-container-choice-summary-glypg-margin-left;
    margin-right: $search-result-container-choice-summary-glypg-margin-right;
    vertical-align: text-top;

    &:after {
      @include msv-icon();
      content: $msv-Cancel;
    }
  }

  .msc-choice-summary__clear-all:after {
    display: none;
  }
}

.ms-search-result-container__Sort-by-category {
  padding-left: $search-result-container-sort-by-category-padding-left;
  position: absolute;
  right: $search-result-container-sort-by-category-right;
  top: $search-result-container-nav-height + $search-result-container-nav-margin-bottom +
    $search-result-container-nav-margin-top;

  .msc-l-dropdown__label {
    margin-right: $search-result-container-sort-by-category-dropdown-label-margin-right;
  }

  .msc-l-dropdown {
    background: transparent;
    border: 1px solid;
    height: $search-result-container-sort-by-category-dropdown-height;
  }
}

.msc-product__text {
  display: none;
}

.ms-product-search-result__item {
  padding: $search-result-container-product-search-result-item-padding;
  position: relative;
  z-index: 1;
  display: inline-block;
  max-width: 100%;
  height: 330px;
  padding: 0;
  margin-right: 2px;
  border-top: solid 1px $color-silver;
  vertical-align: top;
  line-height: 18px;
  > a {
    text-decoration: none;
    color: #3e280d;
    padding: 0;
  }

  .msc-product {
    display: block;
  }

  .msc-product__image img {
    display: block;
    margin: auto;
    position: absolute;
    left: 0;
    bottom: 15px;
    width: 100%;
    max-height: 184px;
    object-fit: cover;
  }

  .product-type-bottled-wine,
  .product-type-canned-wine,
  .product-type-small-bottle {
    .product-details {
      .product-attribute.appellation {
        display: block;
      }

      .product-attribute.description {
        display: -webkit-box;
      }

      .product-details-header {
        padding-left: 90px;
      }
    }

    .product-placement__item-image,
    img {
      bottom: 52px;
      width: auto;
      object-fit: fill;
    }
  }

  .product-type-bottled-wine {
    .product-placement__item-image,
    img {
      max-width: 60px;
      max-height: 260px;
      left: 18px;
    }
  }

  .product-type-canned-wine {
    .product-placement__item-image,
    img {
      max-width: 57px;
      max-height: 120px;
      left: 18px;
    }
  }

  .product-type-small-bottle {
    .product-placement__item-image,
    img {
      max-width: 40px;
      max-height: 135px;
      left: 26px;
    }
  }

  .product-type-assembly {
    .product-placement__item-image,
    img {
      object-fit: contain;
    }

    .product-details-header {
      padding: 48px 30px 30px 90px;
    }
  }

  .msc-product__details {
    width: 100%;
    color: $color-link-primary;
    font-size: 13px;
    line-height: 1.5;
    .fa-chevron-right {
      color: $color-black;
      float: right;
      margin: 55px 10px 0px 0px;
      font-style: normal;
      &::before {
        @include font-awesome-icon($msv-ChevronRight);
      }
    }
    .product-details {
      width: 100%;
    }
    .product-details-header {
      background: $color-grey;
      padding: 30px;
      height: 130px;
    }

    .appellation {
      display: none;
      color: $color-text-black;
      font-size: $font-size-body-xs;
      text-transform: uppercase;
    }

    .description {
      display: none;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 20px 15px 0 90px;
      color: $color-text-black;
      font-size: 13px;
      line-height: 1.5;
      -webkit-line-clamp: 4;
    }

    .acclaim-list {
      padding: 8px 0 0 90px;
      max-width: 240px;
      min-height: 85px;
      color: $color-dovegray;
    }

    .acclaim-entry {
      float: left;
      padding-top: 10px;
      .acclaim-rating {
        min-height: 23px;
        font-family: $font-family-body;
        font-size: 23px;
        line-height: 0.9;
        text-transform: lowercase;
      }
      .acclaim-source {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
      }
    }

    .product-placement__item-title {
      top: 0;
      height: 75px;
      color: $color-black;
      font-family: $font-family-heading;
      font-size: $font-size-heading-shop-xs;

      &:hover,
      &:focus,
      &:active {
        color: $color-text-black;
        text-decoration: underline;
        text-decoration-color: $color-black;
      }
    }
  }
  .product-lightbox {
    display: none;
    position: absolute;
    bottom: 50px;
    right: 15px;

    @include media-breakpoint-up(sm) {
      display: block;
    }

    .launch-lightbox-button {
      width: 55px;
      text-align: center;
      border: none;
      padding: 3px 0 2px;
      background-color: $color-grey;
      color: $color-black;
      font-size: $font-size-link-xs;
      line-height: 1.3;

      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
        border: 1px solid $color-black;
        background-color: $color-grey;
        color: $color-text-black;
      }
    }
  }
}

.ms-product-search-result__item a {
  .msc-product__title {
    // margin-bottom: $search-result-container-product-search-item-title-margin-bottom;
    margin-top: 0;
  }

  .msc-rating {
    display: block;
    margin-top: $search-result-container-product-search-item-rating-margin-top;
  }

  &:focus {
    // @include basic-outline-offset($product-search-result_link__item-outline-width, $product-search-result_link__item-outline-style, $color-link-primary, $product-search-result_link__item-outline-offset);
    .msc-product__title {
      text-decoration: underline;
    }
  }
}
.gift {
  .ms-product-search-result__item {
    .msc-product__details {
      .product-details-header {
        padding: 30px;
      }
    }
    .msc-product__image img {
      bottom: 15px;
      width: 100%;
      max-width: 100%;
      left: 0;
    }
  }
}
.ms-search-result-container__category-hierarchy {
  @include vfi();
}

nav.ms-search-result-container__pagination {
  width: 100%;
}

.msc-pagination {
  display: flex;
  list-style: none;
  padding-left: $search-result-container-pagination-padding-left;
  width: 100%;
}

.previous.msc-page-item {
  display: list-item;
  flex-grow: 1;
  padding-right: $search-result-container-page-item-previous-padding-right;
  text-align: left;

  .msc-pagination__prev {
    padding-left: 4px;
    display: inline-block;
  }

  .msc-page-link {
    &:before {
      @include msv-icon();
      content: "";
    }

    .ms-search-result__pagination-left {
      @include add-icon($msv-ChevronLeft, before);
    }
  }
}

.msc-page-item {
  padding-right: $search-result-container-page-item-padding-right;
}

.next.msc-page-item {
  display: list-item;
  flex-grow: 1;
  padding-right: $search-result-container-page-item-next-padding-right;
  text-align: right;

  .msc-pagination__next {
    padding-right: 4px;
    display: inline-block;
  }

  .msc-page-link {
    padding-right: 4px;

    &:after {
      @include msv-icon();
      content: "";
    }

    .ms-search-result__pagination-right {
      @include add-icon($msv-ChevronRight, after);
    }
  }
}

.msc-page-link {
  @include vfi();
}

.prev-text {
  padding: $search-result-prev-next-padding;
}
.next-text {
  padding: $search-result-prev-next-padding;
}

@include media-breakpoint-down(lg) {
  .ms-search-result-container {
    margin-top: $search-result-container-margin-top-margin-top-mobile;

    .ms-search-result-container__category-hierarchy {
      display: none;
    }

    .msc-button {
      background: none;
      border-style: none;
      float: right;
      font-weight: bold;
    }

    .msc-button:hover {
      text-decoration: underline;
    }

    .ms-product-search-result__item {
      display: inline-block;
      vertical-align: top;
    }
  }

  .ms-search-result-container__pagination {
    margin-bottom: $search-result-container-pagination-margin-bottom-mobile;
  }

  .ms-search-result-container__Products {
    margin-bottom: $search-result-container-products-margin-bottom-mobile;
  }

  .prev-text {
    display: none;
  }

  .next-text {
    display: none;
  }
}

// modal
.msc-search-result-modal {
  .msc-review-modal-body {
    height: $search-result-modal-body-height;
    overflow-y: auto;
    padding: $search-result-modal-body-padding;
  }

  .ms-sort-and-filter-modal-close.msc-btn {
    color: $search-result-modal-footer-button-color;
    background-color: $search-result-modal-footer-button-background-color;
    border-color: $search-result-modal-footer-button-border-color;
    min-width: $search-result-modal-footer-button-min-width;
  }

  .ms-search-result-container__refiner-section {
    display: block;
    padding-right: $search-result-modal-refiner-section-padding-right;
    width: 100%;
  }
}

@media (max-width: $msv-breakpoint-m) {
  .ms-search-result-container__Sort-by-category {
    position: relative;
    top: $search-result-container-sort-by-category-top-mobile;
  }

  .ms-search-result-container,
  .sort_and_filter_mobile_view {
    padding: 0;
    margin: auto;
  }
  .sort_and_filter_mobile_view {
    padding: 0 15px;
  }

  .ms-sort-and-filter-modal-close.msc-btn {
    width: 100%;
  }
}
@include breakpoint(lg) {
  .sort_and_filter_mobile_view {
    padding: 0;
  }
}
