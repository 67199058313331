$buttons-padding-mobile: 0.3rem;
$buttons-padding-desktop: 0.3rem 0.6rem;
//Signin Popover Variables
$popover-margin-top: 0.6rem;
$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-margin: 0 0.3rem;
$popover-arrow-top-position: calc(-0.5rem - 1px);
$popover-arrow-border-width: 0 0.5rem 0.5rem 0.5rem;
$popover-arrow-border-color: rgba(0, 0, 0, 0.25);
header,
.ms-header__topbar  {
  background-color: $color-black;
  @include breakpoint(lg) {
    background-color: transparent;
  }
  background-image: none;
}
.ms-header {
  background-color: $color-background-body;
  background-image: none;
  &__modal {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  @include media-breakpoint-up(lg) {
    background-image: linear-gradient(
      180deg,
      $color-black 0%,
      lighten($color-black, 9) 40%,
      lighten($color-black, 12) 60%,
      lighten($color-black, 14) 80%,
      lighten($color-black, 12) 95%,
      lighten($color-black, 10) 100%
    );
  }
  &__container {
    position: relative;
    max-width: 100%;
    padding: 0;
    @include media-breakpoint-up(lg) {
      width: 100%;
      max-width: 960px;
      margin: 0 auto;
    }
  }
  &__topbar {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 0.45rem 0 0.4rem;
    max-width: 960px;
    margin: 0 auto;
    @include breakpoint(lg) {
      padding: 0.45rem 0 0.55rem;
      max-width: 100%;
    }
  }
  &__logo {
    position: absolute;
    z-index: 55;
    top: 3.55rem;
    left: 0;
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      img {
        max-width: 250px;
        height: auto;
      }
    }
  }
  .mobile-vp {
    &.navbar-collapse {
      visibility: hidden;
      max-height: 0;
      overflow: hidden;
      transition: all 1s ease;
    }
    &.nav-open {
      max-height: 1200px;
      transition: all 2s ease;
      visibility: visible;
    }
    @include media-breakpoint-up(lg) {
      display: none;
    }
    .ms-header {
      &__logo {
        display: block;
        top: 3.32rem;
        left: 1.25rem;
        img {
          max-width: 180px;
          height: auto;
        }
      }
    }
  }
  .ms-search {
    display: flex;
    justify-content: flex-end;
    flex-basis: 0;
    flex-grow: 1;
    .bx-hide {
      display: none;
    }
    &__icon {
      &:before {
        @include msv-icon();
        content: $msv-magnifying-glass;
        margin-right: 0.35rem;
        font-size: $font-size-search-icon;
      }
    }
    &__form-cancelSearchNoSumbit {
      margin-left: -25px;
      padding: 0 0.5rem !important;
      line-height: 0.8;
      &::before {
        font-size: $font-size-search-icon;
      }
    }
  }
  .msc-cart-icon {
    > * {
      float: left;
    }
    &::before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($fa-var-shopping-cart);
      margin-right: 0.35rem;
      font-size: $font-size-link-s;
      float: left;
      margin-top: 0.35rem;
    }
  }
  .msc-btn {
    @extend .no-underline;
    font-family: $font-family-link;
    font-size: $font-size-link-l;
    color: $color-link-header;
    text-transform: capitalize;
    transition: 0.3s all ease;
    background: none;
    border: none;

    &:hover,
    &:focus,
    &:active {
      color: $color-link-header-hover;
    }
    padding: 0.2rem 0.75rem;
    display: block;
  }
  .ms-header {
    &__nav-icon {
      display: block;
      float: right;
      padding: 1rem 2.4rem;
      z-index: 80;
      position: relative;
      &::before {
        @include msv-icon();
        content: $msv-GlobalNavButton;
        font-size: 1.25rem;
        color: $color-dustygray;
        vertical-align: text-bottom;
        text-align: center;
      }
      &:hover {
        text-decoration: none;
      }
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
    &__profile-button {
      text-decoration: none;
    }
  }
}

//popover
.msc-popover {
  @extend %popover;
  margin-top: $popover-margin-top;
  border-radius: 0;
  .msc-arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: $popover-arrow-margin;
    top: $popover-arrow-top-position;
    &:before,
    &:after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
    &:before {
      top: 0;
      border-width: $popover-arrow-border-width;
      border-bottom-color: $popover-arrow-border-color;
    }
    &:after {
      top: 1px;
      border-width: $popover-arrow-border-width;
      border-bottom-color: $color-white;
    }
  }
}
//signin popover
.ms-header__account-info {
  .msc-popover-inner {
    padding: 0.5rem 0.75rem;
    color: $popover-body-color;
    display: flex;
    flex-direction: column;

    .ms-signin-info__account-link-button,
    .ms-header__signout-button {
      @extend %btn-default;
      border: none;
      background-color: transparent;
      color: $color-link-header;
      font-family: $font-family-link;
      font-size: $font-size-link-s;
      font-weight: bold;
      letter-spacing: 2px;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;

      &:hover,
      &:focus,
      &:active,
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):focus,
      &:not(:disabled):not(.disabled):active:focus {
        border: none;
        box-shadow: none;
        background-color: transparent;
        color: $color-dovegray;
        text-decoration: none;
      }
    }
  }
}

//Account Header Spacing Fix
.account-header {
  .ms-header__topbar {
    height: 41px;
    background: transparent;
    @include media-breakpoint-up(lg) {
      height: 44px;
    }
  }
}
