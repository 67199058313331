.msc-promo-code {
  &__applied-code-header {
    @include font-content-l();
  }
  &__line-container {
    display: flex;
    justify-content: space-between;
  }

  &-heading {
    margin-bottom: 0.5rem;
    @extend %text-shop-subheading;
  }

  &__line-value {
    display: block;
  }
  &__applied {
    &-heading {
      display: flex;
      justify-content: space-between;
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: 1px solid $color-border-primary;
      color: $color-link-primary;
      font-size: $font-size-body-l;
      font-weight: bold;
    }
    &-subheading {
      margin-bottom: 0.5rem;
      color: $color-text-description;
      font-size: $font-size-body-s;
      font-style: italic;
    }
  }
  &__line-value-code {
    margin-right: 0.25rem;
    font-size: 14px;
  }
  &__line-value-price {
    font-size: 14px;
    font-weight: bold;
  }
  &__line__btn-remove {
    @extend %link-shop;
    font-size: $font-size-body-s;
  }
}