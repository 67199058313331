.ms-checkout-delivery-options {
  &__option {
    @extend %form-check;
    &-selected {
      .ms-checkout-delivery-options__description {
        margin-left: 0;
      }
    }
  }
  &__input-radio {
    @extend %form-check-input;
    margin-left: 0;
  }
  &__description {  
    margin-left: 1.25rem;
  }
  &__price {
    margin-left: 1.45rem;
  }
}
