.content-marketing {
  h1,
  .h1 {
    @extend %text-marketing-heading;
    margin-bottom: 1rem;
  }

  h2,
  .h2 {
    @extend %text-marketing-heading;
    font-size: $font-size-heading-s;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-heading-m;
    }
  }

  h3,
  .h3 {
    @extend %text-marketing-heading;
    font-size: $font-size-heading-xxs;
    margin-bottom: 1rem;
  }

  h4,
  .h4 {
    @extend %text-marketing-subheading;
    margin-bottom: 1rem;
  }

  a {
    color: inherit;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }
  }

  p a,
  p button {
    @extend %link-marketing;
  }

  .hero-cta a,
  .hero-cta button,
  .btn-marketing {
    @extend %btn-marketing;
  }

  .link-marketing {
    @extend %link-marketing;
  }
}

.content-marketing-single {
  @extend .content-marketing;
  max-width: $max-marketing-page-width;
  min-height: 500px;
  padding: 2rem;
  margin: 0 -15px;

  @include media-breakpoint-up(sm) {
    margin: 2rem auto;
    // background-image: $asset-background-single;
    background-position: top left;
    background-size: 600px;
    background-repeat: no-repeat;
  }

  @include media-breakpoint-up(lg) {
    margin: 4rem auto;
  }

  .container-with-header-header {
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid $color-border-tertiary;
  }
  .container-with-header-title {
    // text-align: center;
    max-width: 600px;
    margin-left: auto;
  }

  .container-with-header-slots {
    max-width: 600px;
    margin-left: auto;
  }
}

.content-marketing-single-continue {
  @extend .content-marketing-single;
  margin-top: -1rem;
  background-image: none;

  @include media-breakpoint-up(lg) {
    margin-top: -3rem;
  }

  .container-with-header-header {
    display: none;
  }
}

.content-marketing-double {
  @extend .content-marketing;
  max-width: $max-marketing-page-width;
  margin: 2rem;

  @include media-breakpoint-up(lg) {
    margin: 4rem auto;
  }

  &:first-child,
  + .content-marketing-double {
    margin-top: 0;

    .col:first-child {
      margin-top: 0;
    }
  }

  a,
  button,
  .youtube-modal-button {
    @extend %link-marketing;
    transition: all 0.3s ease;
  }

  .call-to-action {
    @extend %btn-marketing;
  }

  ul {
    padding-left: 1rem;

    li {
      margin-bottom: 0.5rem;
    }
  }
}
.content-marketing-double-right {
  .content-with-media-text {
    ul,
    li {
      list-style: disc;
    }
  }
}
.content-marketing-double-left,
.content-marketing-double-right {
  @extend .content-marketing-double;

  > .col {
    display: flex;
    flex: auto;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .content-with-media-media-image {
    padding: 2rem;

    @include media-breakpoint-down(md) {
      padding: 2rem 0;
      padding-bottom: 1rem;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.content-marketing-double-left > .col:first-child,
.content-marketing-double-right > .col:last-child {
  align-items: center;

  @include media-breakpoint-up(md) {
    width: 30%;
  }
}

.content-marketing-double-left > .col:last-child,
.content-marketing-double-right > .col:first-child {
  justify-content: center;

  @include media-breakpoint-up(md) {
    width: 70%;
  }
}

.content-marketing-double-left {
  flex-direction: column-reverse;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  .content-with-media-text {
    ul,
    li {
      list-style: disc;
    }
  }
}

.content-marketing-quote {
  @extend .content-marketing-double;

  > .row {
    margin: 0;
  }

  > .row > div {
    display: flex;
    flex: auto;
    width: 100%;
    padding: 0;
    margin: 2rem 0;
  }

  > .row > div:first-child {
    @include media-breakpoint-up(md) {
      width: 30%;
    }
  }

  > .row > div:last-child {
    @include media-breakpoint-up(md) {
      width: 70%;
    }

    .content-block-with-title {
      margin-left: 2rem;

      @include media-breakpoint-up(sm) {
        margin-left: 4rem;
      }

      @include media-breakpoint-up(md) {
        margin-left: 8rem;
      }
    }

    .content-block-with-title-text p {
      position: relative;
      font-size: $font-size-body-xl;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-body-xxl;
      }

      &::before {
        content: "\201C";
        position: absolute;
        top: 0;
        left: 0;
        margin: -1rem 0 0 -2rem;
        font-size: 50px;

        @include media-breakpoint-up(sm) {
          margin: -2.5rem 0 0 -4rem;
          font-size: 100px;
        }
      }
    }
  }
}

.content-marketing-display {
  @extend .content-marketing;
  max-width: $max-marketing-page-width;
  margin: 2rem 2.94rem;

  @include media-breakpoint-up(lg) {
    margin: 4rem auto;
    // background-image: $asset-background-display;
    // background-size: contain;
    // background-repeat: no-repeat;
    // background-position: center;
  }

  .container-with-header-header {
    position: relative;
    // margin-bottom: 2rem;
    text-align: center;

    // border-bottom: 1px solid $color-border-tertiary;

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      top: 1.7rem;
      width: 100%;
      height: 1px;
      background-color: $color-border-tertiary;
      @include media-breakpoint-down(md) {
        top: 1.4rem;
      }
    }

    .container-with-header-title {
      width: fit-content;
      margin: auto;
      background-color: $color-white;
      padding: 0.5rem;
      border-bottom: 2px solid;
      padding-bottom: 2px;
      margin-bottom: 0.5rem;
    }

    h3,
    .h3 {
      display: inline-block;
      padding: 0.5rem;
      background-color: $color-background-body;
      color: $color-text-black;
      font-size: $font-size-heading-xs;
    }
  }

  .container-with-header-slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > a {
      // flex: 0 0 100%;
      flex: 1 1 25%;

      // @include media-breakpoint-down(md) {
      //   flex: 1 1 50%;
      // }

      // @include media-breakpoint-up(lg) {
      //   flex: 1 1 25%;
      // }
      color: $color-text-black;
      @include media-breakpoint-down(sm) {
        .ms-content-block {
          padding: 0;
          .ms-content-block__image img {
            min-height: 200px;
            height: 230px;
          }
        }
      }
      .ms-content-block__title {
        color: $color-text-black;
      }
    }

    .hero,
    .ms-content-block {
      padding: 1rem;
      text-align: center;

      .hero-picture img,
      .ms-content-block__image img {
        width: auto;
        height: 400px;
        margin-bottom: 1rem;
      }

      .hero-text-alignment-container,
      .ms-content-block__details {
        h3,
        .h3 {
          color: $color-heading-tertiary;
        }
      }
    }
  }
}

.content-marketing-timeline {
  @extend .content-marketing;
  max-width: $max-marketing-page-width;
  margin: 2rem;

  @include media-breakpoint-up(lg) {
    margin: 4rem auto;
  }

  .container-with-header-header {
    text-align: center;
    margin-bottom: 4rem;

    @include media-breakpoint-up(md) {
      margin: 0;
    }
  }

  .content-marketing-slots {
    margin: 5rem 0;
  }

  .content-marketing-double-left,
  .content-marketing-double-right {
    margin: 0 0 3rem;

    @include media-breakpoint-up(md) {
      margin: 0;
    }

    > .col {
      margin: 0;

      @include media-breakpoint-up(md) {
        width: 50%;
      }

      img {
        z-index: -1;
      }

      .content-with-media-text {
        z-index: 20;
        font-size: $font-size-body-xl;

        h1,
        .h1 {
          margin-bottom: 0;
        }
      }
      .content-with-media-media-image {
        @include media-breakpoint-down(sm) {
          padding: 0;
          padding-bottom: 1rem;
        }
        img {
          aspect-ratio: 530/430;
          object-fit: cover;
        }
      }
    }
  }

  // .content-marketing-double-left > .col:last-child,
  // .content-marketing-double-right > .col:first-child {
  //   margin: -5rem 0;

  //   @include media-breakpoint-up(md) {
  //     margin: -4rem 0;
  //   }
  // }

  .content-marketing-double-left {
    flex-direction: column-reverse;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .col {
      @include media-breakpoint-up(md) {
        justify-content: flex-end;
      }
    }

    .content-with-media-text {
      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }
  }
}

.content-marketing-contact {
  @extend .content-marketing;
  padding: 3rem 0;
  margin: -15px 0;
  // background-image: $asset-background-contact;
  background-size: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;

  @include media-breakpoint-up(lg) {
    padding-bottom: 10rem;
  }

  .container-with-header-header,
  .container-with-header-slots {
    max-width: $max-marketing-page-width;
    margin: 2rem;

    @include media-breakpoint-up(lg) {
      margin: 4rem auto;
    }
  }

  .container-with-header-slots {
    display: flex;
    flex-wrap: wrap;

    > * {
      flex: 0 0 100%;

      @include media-breakpoint-up(lg) {
        flex: 1 1 calc(96% / 3);
        max-width: calc(96% / 3);
      }

      &:not(:first-child) {
        @include media-breakpoint-up(lg) {
          margin-left: 2%;
        }
      }
    }
  }

  .container-with-header-title {
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid $color-border-tertiary;
    text-align: center;
  }

  .content-block-with-title-title {
    font-size: $font-size-heading-xs;
  }
}

.content-marketing-reserve,
.content-marketing-cabernet,
.content-marketing-sustainability {
  .content-with-media-media-image img {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
}

.content-marketing-club {
  @extend .content-marketing;
  max-width: 600px;
  margin: 2rem;
  text-align: center;

  @include media-breakpoint-up(sm) {
    margin: 2rem auto;
  }

  @include media-breakpoint-up(lg) {
    margin: 4rem auto;
  }
  ul,
  li {
    list-style: disc;
  }
  ul {
    display: inline-block;
    margin: auto;
    text-align: left;
  }
}

.content-marketing-faq {
  @extend .content-marketing;
  display: flex;
  flex-wrap: wrap;
  max-width: $max-marketing-page-width;
  padding: 2rem 0;
  margin: 0 1rem;
  border-top: 1px solid $color-border-tertiary;
  ul,
  li {
    list-style: disc;
  }
  @include media-breakpoint-up(lg) {
    margin: 0 auto;
  }

  > * {
    flex: 0 0 100%;
  }

  .content-block-with-title-title {
    @include media-breakpoint-up(lg) {
      flex: 1 1 35%;
      margin-right: 5%;
    }
  }

  .content-block-with-title-text {
    @include media-breakpoint-up(lg) {
      flex: 1 1 60%;
    }
  }
}

.content-marketing-responsibility {
  ul,
  li {
    list-style: disc;
  }
  @extend .content-marketing-single;

  // @include media-breakpoint-up(sm) {
  //   background-image: $asset-background-responsibility;
  // }
  padding: 2rem;
  .container-with-header-slots {
    position: relative;
    margin-bottom: 6rem;

    &::after {
      content: "";
      position: absolute;
      width: 160px;
      height: 30px;
      background-image: $asset-image-responsibility;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.content-marketing-events #subfooter {
  display: none;
}

[class*="hero-marketing"] {
  // added bottom space for all hero-marketing class
  margin-bottom: 1rem !important;

  //Making the heading color inherit
  .ms-content-block__title {
    color: inherit;
  }

  //text on top of hero image to have inherited text color
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .ms-content-block__text {
    color: inherit;
  }
}

.hero-marketing {
  @extend .content-marketing;
  z-index: 0 !important;

  .hero,
  &.ms-content-block,
  &.hero {
    position: relative;
    z-index: 0 !important;
  }

  .hero-text-alignment-container,
  .ms-content-block__details {
    position: absolute;
    width: 100%;
    max-width: 800px;
    padding: 1rem;
    color: $color-text-tertiary;
    text-shadow: 0 0 4px transparentize($color-black, 0.4);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    top: auto;
    bottom: 5%;
    @include breakpoint(sm) {
      padding: 1rem 3rem;
    }

    &.x-right {
      left: auto;
      right: 5%;
    }

    &.y-center {
      top: 50%;
      transform: translateY(-50%);
    }

    &.x-center.y-center {
      transform: translate(-50%, -50%);
    }
  }

  p {
    color: $color-text-secondary;
    font-size: $font-size-body-xl;
    line-height: 1.5;
  }
}

.hero-marketing-background {
  @extend .hero-marketing;

  .hero,
  &.hero {
    z-index: -1;
    height: auto;
    min-height: 0;
    margin: -3rem -15px 3rem;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      margin: -10rem -15px 10rem;
    }
  }
}

.hero-marketing-top {
  @extend .hero-marketing;
  z-index: 0;

  .hero,
  &.ms-content-block,
  &.hero {
    z-index: -1;
    max-width: 4000px;
    min-height: 400px;
    position: relative;
    @include media-breakpoint-up(sm) {
      // height: 40vw;
      height: auto;
      max-height: 700px;
    }

    img {
      height: 45vw;
      min-height: 350px;
      max-height: 700px;
    }

    .ms-content-block__text {
      p a {
        text-transform: none;
        font-family: $font-family-body;
        font-size: $font-size-body-xl;
        letter-spacing: 0;
      }
    }
  }

  .hero-text-alignment-container,
  .ms-content-block__details {
    position: static;
    transform: none;
    margin-top: -10rem;

    @include media-breakpoint-up(sm) {
      position: absolute;
      transform: translateX(-50%);
      margin-top: 0;
    }
  }

  p {
    @include media-breakpoint-up(md) {
      padding: 0 4rem;
    }
  }
}

.hero-marketing-double {
  @extend .content-marketing;
  margin: 1rem 0;

  .marketing-home &.default-container-2-slots__0 {
    .ms-content-block {
      &__image {
        img {
          @include media-breakpoint-up(md) {
            min-width: 494px;
            min-height: 296px;
          }
        }
      }
    }
  }
  > .row {
    justify-content: center;
    margin: 0;

    > div {
      padding: 0;
      &.col-md-6 {
        @include media-breakpoint-between(sm, md) {
          max-width: 48%;
          img {
            min-width: auto !important;
          }
        }
      }
    }

    > div:first-child {
      @include media-breakpoint-up(lg) {
        margin-right: 1rem;
      }
      @include media-breakpoint-between(sm, md) {
        &.col-md-6 {
          margin-right: 1rem;
        }
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }
  }

  .ms-content-block {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: unset;
      max-height: unset;
    }
    &__cta, &__text {
      a {
        @extend %btn-default;
        @extend .btn-black;
        @extend %btn-marketing;
        font-size: 14px;
        padding: 0.5rem 1rem;
        color: $color-white;
        border-color: $color-button-marketing-background-hover;
      }
    }
  }

  .ms-content-block__details {
    bottom: 5%;
    left: 5%;
    width: auto;
    padding: 1rem;
    color: $color-text-tertiary;
    text-shadow: 0 0 4px transparentize($color-black, 0.4);
    position: absolute;
  }

  h3,
  .h3 {
    color: $color-heading-tertiary;
    text-shadow: 0 0 5px $color-black;
    font-size: $font-size-heading-xs;
  }
}

.hero-marketing-video {
  .hero-marketing-video-top-text {
    .ms-content-block__details {
      top: 0;
    }
  }
  .hero-marketing-video-top-text.video-top-text {
    .ms-content-block__text {
      p {
        padding: 0;
        color: $color-silver;
        font-size: 1rem;
        text-transform: unset;
        @include breakpoint(sm) {
          padding: 0 2rem;
        }
      }
    }
  }
  @extend .hero-marketing;

  .hero-text-alignment-container,
  .ms-content-block__details {
    padding: 3rem 1rem 1rem;

    @include media-breakpoint-up(sm) {
      padding: 6.8rem 3rem 1rem;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }
  }
  .ms-content-block__image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-height: unset;
      min-height: unset;
    }
  }
  h2 {
    font-size: $font-size-heading-m;

    @include media-breakpoint-up(sm) {
      font-size: $font-size-heading-l;
    }
  }

  p {
    padding: 0;
    font-size: $font-size-body-s;
    color: $color-white;
    text-transform: uppercase;
    @include media-breakpoint-up(sm) {
      padding: 0;
    }
  }

  > .row {
    position: relative;
    // min-height: 600px;
    margin: 0;

    // @include media-breakpoint-up(lg) {
    //   min-height: 700px;
    // }

    // @include media-breakpoint-up(xl) {
    //   min-height: 800px;
    // }

    > div:last-child:not(:only-child) {
      position: absolute;
      top: auto;
      bottom: 0;
      width: auto;
      // padding: 1rem;

      @include media-breakpoint-up(sm) {
        top: 50%;
        bottom: 3rem;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
      }

      @include media-breakpoint-up(md) {
        top: 45%;
      }

      @include media-breakpoint-up(lg) {
        top: 40%;
      }

      @include media-breakpoint-up(xl) {
        top: 35%;
      }
    }
    > div {
      padding: 0;
    }
  }

  .youtube-modal-clickable {
    position: relative;
    overflow: hidden;
    // box-shadow: 0 0 10px $color-black;
    cursor: pointer;
    @include media-breakpoint-up(lg) {
      margin: auto;
      max-width: 1004px;
    }
    .youtube-modal-textblock {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 0;
      text-align: center;
      text-shadow: 0 0 5px $color-black;
      p,
      h3 {
        letter-spacing: 1px;
        font-family: $font-family-link;
        border: 1px solid white;
        border-radius: 2%;
        padding: 5px;
      }
      &::after {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-play-circle);
        font-size: $font-size-icon-l;
        color: white;
      }
    }

    img {
      max-width: 100%;
      height: auto;
      transition: 0.3s all ease;
      min-width: 1000px;
      @include media-breakpoint-down(md) {
        min-width: 100vw;
      }
    }

    &:hover,
    &:focus,
    &:active {
      img {
        transform: scale(1.02);
      }
    }
  }
}
.hero-marketing-video {
  .col-12 {
    padding: 0;
  }
}
.hero-marketing-reserve,
.hero-marketing-cabernet {
  @extend .hero-marketing-top;
  position: relative;
  z-index: 0;

  .hero-text-alignment-container::before,
  .ms-content-block__details::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, -110%);
    width: 285px;
    height: 150px;
  }

  .hero-text-alignment-container,
  .ms-content-block__details {
    margin-top: 0;

    @include media-breakpoint-up(sm) {
      margin-top: -10rem;
    }
    .ms-content-block__text {
      margin-top: 1rem;
    }
  }
}

.hero-marketing-cabernet {
  @extend .hero-marketing-top;
  position: relative;

  .ms-content-block__details {
    margin-top: -3.1rem;
    @include breakpoint(sm) {
      margin-top: 0;
    }
  }
}

.hero-marketing-reserve .ms-content-block__details::before {
  background-image: $asset-image-reserve;
}
.hero-marketing-reserve {
  @include breakpoint(xs) {
    .ms-content-block__image {
      min-height: 350px;
    }
  }
  @include breakpoint(sm) {
    .ms-content-block__image {
      min-height: 400px;
    }
  }
}

.hero-marketing-cabernet .ms-content-block__details::before {
  background-image: $asset-image-cabernet;
}

.MsoTableGrid {
  margin-bottom: 1.5rem;
  font-size: $font-size-body-m;
  line-height: 1.4;

  td {
    border: 1px solid transparentize($color-border-primary, 0.65);
    width: 50%;
    vertical-align: top;
    padding: 0.3rem 0.5rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
.ms-content-block__image {
  img {
    height: 45vw;
    min-height: 350px;
    max-height: 700px;
    width: 100%;
    object-fit: cover;
  }
}

.hero-marketing-main .ms-content-block__image img {
  height: unset;
  min-height: unset;
  max-height: unset;
}

.find-wine,
.find-wine-result {
  @extend .content-marketing-single;
  margin-bottom: 4rem;
  div {
    margin: 0;
    padding: 0;
  }
  input,
  input:focus,
  select,
  select:focus {
    @extend .form-control;
    @extend %input-marketing;
    max-width: 400px;
    color: $color-dovegray;
    border: none;
    background-color: $color-gallery;
    font-family: $font-family-body;
    padding: 0.25rem 0.5rem;
  }
  label {
    margin: 0.5rem 0 0;
    font-size: 13px;
  }
  .find-wine-query-col {
    display: flex;
    flex-wrap: wrap;
  }
  .find-wine-info-text {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .find-wine-title {
    @extend %text-marketing-heading;
    font-size: 42px;
  }
  .find-wine-query,
  .find-wine-query-int {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 3rem;
    @include media-breakpoint-up(lg) {
      flex: 1 1 calc(50% - 1.5rem);
      max-width: calc(50% - 1.5rem);
    }
  }
  .find-wine-query-int {
    @include media-breakpoint-up(lg) {
      margin-left: 3rem;
    }
  }
  .find-wine-search-text,
  .find-wine-result-title p {
    @extend %text-marketing-featured;
  }
  .find-wine-query-title,
  .find-wine-query-int-title {
    @extend %text-marketing-subheading;
    color: $color-white;
    font-size: 13px;
  }
  .find-wine-query-search-button,
  .find-wine-query-int-button {
    @extend .btn;
    @extend .btn-black;
    margin-top: 1rem;
  }
  .find-wine-result-back-link:not([href]):not([tabindex]) {
    @extend %link-marketing;
  }
  .find-wine-results {
    padding-top: 3rem;
    margin-top: 3rem;
    border-top: 1px solid transparentize($color-border-primary, 0.8);
  }
  .find-wine-results-page-info-border {
    display: flex;
    justify-content: space-between;
  }
  .find-wine-results-page-info-border-page-count {
    margin-bottom: 0;
    font-style: italic;
    font-size: 13px;
    @media (min-width: 576px) {
      font-size: 18px;
    }
  }
  .find-wine-pager {
    display: flex;
    > * {
      @extend %link-marketing;
      padding: 0 0.5rem;
    }
  }
  .find-wine-display-item-domestic,
  .find-wine-display-item-int {
    margin: 2rem 0;
    > * {
      margin-bottom: 0;
    }
    &-location {
      font-size: 13px;
    }
  }
  .find-wine-display-item-domestic-title,
  .find-wine-display-item-int-title {
    font-family: $font-family-body;
    font-size: $font-size-body-xxl;
  }
  .find-wine-display-item-domestic-distance,
  .find-wine-display-item-int-distance {
    font-style: italic;
    font-size: 13px;
    @media (min-width: 576px) {
      font-size: 18px;
    }
  }
  .find-wine-display-item-map {
    font-size: 13px;
    @media (min-width: 576px) {
      font-size: 18px;
    }
  }
  .find-wine-wine-display-toggle {
    @extend .btn;
    @extend %link-marketing;
    margin: 1rem 0 0.5rem;
  }
  .find-wine-wine-diplay-wines p {
    margin: 0;
    font-style: italic;
  }
  .find-wine-legal {
    padding: 1rem 0;
    margin: 4rem 0 0;
    border-top: 1px solid transparentize($color-border-primary, 0.8);
    font-size: $font-size-body-m;
  }
  .find-wine-contact {
    margin-top: 3rem;
    font-size: 13px;
    font-family: $font-family-body;
  }
}

.sitemap-margin {
  .container-with-header-slots {
    ul,
    li {
      list-style: disc;
    }
  }
}
.marketing-responsibly-margin {
  .content-marketing-responsibility {
    padding: 2rem;
  }
}

.carousel-text-placement {
  height: auto;
  max-height: 700px;
  min-height: unset;
  position: relative;
}

.hero-marketing-background.manage-background {
  z-index: -1;
  height: auto;
  min-height: 0;
  margin: -3rem -15px 3rem;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-up(md) {
    margin: -10rem -15px 10rem;
  }
  .ms-content-block__image img {
    height: 100%;
    min-height: auto;
    max-height: unset;
  }
}

// .content-marketing-timeline {
//   .content-marketing-double-left > .col:last-child,
//   .content-marketing-double-right > .col:first-child {
//     margin: -5rem 0;

//     @include media-breakpoint-up(md) {
//       margin: -7rem 0;
//     }
//   }
// }

.hero-marketing-top.winery-events-margin,
.hero-marketing-reserve.reserve-club-margin,
.hero-marketing-cabernet.cab-collective-margin,
.hero-marketing-top.club-members-margin,
.hero-marketing-top.visit-us-text-placement,
.hero-marketing-top.faq-text-placement,
.hero-marketing-top.winemaking-text-placemenet,
.hero-marketing-top.our-story-text-placemenet {
  margin: 0 -15px;
}

.hero-marketing-top.visit-us-text-placement,
.hero-marketing-top.our-story-text-placemenet,
.hero-marketing-top.winemaking-text-placement,
.hero-marketing-top.club-members-margin,
.hero-marketing-top.faq-text-placement {
  height: auto;
  max-height: 700px;
  min-height: 400px;
  position: relative;
  @include breakpoint(sm) {
    height: auto;
  }
}
.content-marketing-mailing-list {
  padding: 5rem;

  .col-12 {
    padding: 1rem;
  }

  .content-block-with-title-title {
    color: inherit;
  }

  .mailing-list-form-list {
    margin: 0;
  }

  .text-input-group {
    label {
      margin-bottom: 0;
    }

    input.form-control {
      @extend %form-control-el;
      @extend %input-shop;
      border: 1px solid $color-lightbrown;

      &:focus {
        box-shadow: 0 0 0 0.2rem #706a66;
      }
    }
  }

  .text-input-validation-error {
    @extend %error-alert;
    display: block;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0;
    font-size: 14px;
  }

  .input-group-mailing-list-button {
    @extend .btn;
    @extend .btn-black;
  }

  .mailing-list-success-message {
    margin-top: 0.5rem;

    p {
      display: inline-block;
    }
  }
}

.marketing-home {
  .msc-carousel__item {
    z-index: 0 !important;
  }

  .msc-carousel {
    z-index: 0;
    &__item {
      height: 100%;
      @include media-breakpoint-up(md) {
        margin-bottom: 3rem;
      }
    }
  }

  .carousel-text-placement {
    position: relative;
    z-index: -1;
    max-width: 4000px;
    min-height: unset;
    @include media-breakpoint-up(sm) {
      height: 40vw;
      max-height: 700px;
    }
  }

  .msc-carousel__item {
    @extend .hero-marketing-top;
    // margin top deleted at (1200) -4rem
    p {
      @extend %text-marketing-subheading;
      color: $color-heading-tertiary;
      font-size: $font-size-link-xl;
    }
    .ms-content-block__details {
      padding: 1rem 2rem 5rem;
    }
  }
}

// COL - from override 11/5/2022

.hero-marketing-top .hero,
.hero-marketing-reserve .hero,
.hero-marketing-cabernet .hero,
.marketing-home .msc-carousel__item .hero,
.hero-marketing-top.ms-content-block,
.ms-content-block.hero-marketing-reserve,
.ms-content-block.hero-marketing-cabernet,
.marketing-home .ms-content-block.msc-carousel__item,
.hero-marketing-top.hero,
.hero.hero-marketing-reserve,
.hero.hero-marketing-cabernet,
.marketing-home .hero.msc-carousel__item {
  z-index: 0;
}

@include media-breakpoint-up(sm) {
  .marketing-home .hero-marketing-double.default-container-2-slots__0 .ms-content-block__image img {
    min-width: 494px;
    min-height: 296px;
  }
}
