#renderPage {
  height: 100vh;
  display: flex;
  flex-direction: column;

  header {
    z-index: 10;
    flex: 0 1 auto;
    width: 100%;
  }

  main {
    flex: 1 0 auto;
    max-width: none;
    margin: 0;
    background-color: transparent;
  }

  footer {
    z-index: 5;
    flex: 0 1 auto;
    margin-top: auto;
  }
}

.marketing-responsibly-margin,
.content-marketing-events.manage-margin,
.supply-chain,
.club-members-main-margin,
.reserve-club-main-margin,
.sitemap-margin,
.terms-margin,
.our-story-margin,
.cab-collective-main-margin,
.privacy-margin,
.content-marketing-find-wine,
.responsibility-margin,
.faq-main-margin,
.visit-us-margin,
.winemaking-main-margin,
.join-club-main-margin,
.content-marketing-sustainability
{
  #main {
    margin: 0 15px;
    .carousel-text-placement{
      margin: 0 -15px;
    }
  }
}