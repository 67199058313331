$max-height-m: 732px;
$max-height-l: 772px;
$gutter-width: 40px;
$carousel-padding-left: 20px;
$thumbnail-height: 100px;
$thumbnail-flipper-height: 30px;
$thumbnail-flipper-background: $color-white;
$thumbnail-flipper-foreground: $color-dovegray;
$carousel-tooltip-padding: 4px;
$carousel-indicators-border: 1px;

.ms-media-gallery {
  display: inline-flex;

  .ms-media-gallery__thumbnails-container {
    display: none !important;
    order: 0;
    flex-grow: 0;
  }

  .ms-media-gallery__carousel {
    align-items: start;

    order: 1;
    flex-grow: 1;
    @include breakpoint(md) {
      border-bottom: none;
    }
  }

  .msc-ss-carousel-vert__flipper {
    position: relative;
    height: $thumbnail-flipper-height;
    width: 100%;
    border-radius: 0;
    background: $thumbnail-flipper-background;
    border: 1px solid $thumbnail-flipper-background;
    top: auto;
    left: auto;
    transform: none;
    color: $thumbnail-flipper-foreground;

    flex: 0 1 $thumbnail-flipper-height;

    &.disabled {
      display: none;
    }
  }

  .msc-carousel__control__prev,
  .msc-carousel__control__next {
    background-color: $color-black;
  }

  .msc-ss-carousel-vert__flipper--next {
    bottom: auto;
  }

  .msc-tooltip-inner {
    background-color: $color-black;
    color: $color-white;
    padding: $carousel-tooltip-padding $carousel-tooltip-padding;
  }

  .msc-carousel__indicators li {
    &.active {
      background-color: $color-black;
    }
    border: $carousel-indicators-border solid $color-black;
    background-color: $color-white;
  }
}

@media (min-width: $msv-breakpoint-m) {
  .ms-media-gallery {
    .msc-carousel__item {
      max-height: $max-height-m;
    }
  }
}

@media (min-width: $msv-breakpoint-l) {
  .ms-media-gallery {
    height: $max-height-l;

    .msc-carousel__indicators {
      display: none;
    }

    .msc-carousel__control__prev {
      display: none;
    }

    .msc-carousel__control__next {
      display: none;
    }

    .ms-media-gallery__thumbnails-container {
      // display: flex;
      visibility: hidden;
    }

    .ms-media-gallery__thumbnail-item {
      height: $thumbnail-height;
    }
  }
}
