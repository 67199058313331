@font-face {
    font-family: "TradeGothicLTPro-Cn18";
    src: url("fonts/tradegothicprocondensed.eot");
    src: url("fonts/tradegothicprocondensed.eot?#iefix") format("embedded-opentype"),
        url("fonts/tradegothicprocondensed.woff2") format("woff2"),
        url("fonts/tradegothicprocondensed.woff") format("woff"),
        url("fonts/tradegothicprocondensed.ttf") format("truetype");
}

@font-face {
    font-family: "CharterITCStd-Regular";
    src: url("fonts/itccharter.eot");
    src: url("fonts/itccharter.eot?#iefix") format("embedded-opentype"),
        url("fonts/itccharter.woff2") format("woff2"),
        url("fonts/itccharter.woff") format("woff"),
        url("fonts/itccharter.ttf") format("truetype");
}