;
//==============================================================================
// IMPORTS
//==============================================================================
$fa-font-path: "https://use.fontawesome.com/releases/v6.6.0/webfonts" !default;
@import "https://fonts.googleapis.com/css?family=Lato:300,400,700,400italic|Lora:400,700";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "bootstrap/scss/bootstrap";

@import "00-abstract/index";
@import "01-base/index";
@import "02-components/index";
@import "03-modules/index";
@import "04-layout/index";

