$font-age-gate: "Arial", "Helvetica", sans-serif;
$color-age-gate: rgb(20, 20, 20);
$color-age-gate-hover: #646464;
$color-age-gate-button: #6c757d;
$color-age-gate-link: #141414;

.modal-open .age-gate-modal .modal-dialog {
  min-height: 0;
  margin: 1rem auto;
  width: 75%;
  @include media-breakpoint-up(sm) {
    width: auto;
  }

  @include media-breakpoint-up(lg) {
    min-width: 520px;
  }
  
  .modal-content {
    border-radius: 0;
    padding: 0;
    border: 0;
  }
  .modal-body {
    padding: 0;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50.1%) !important;
  margin: auto;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: $color-white;
  padding: 2rem 3rem;
}
.age-gate-container {
  text-align: center;
  padding-top: 20px;
  max-height: 615px;
  h4,
  .h4 {
    font-size: 14px;
    color: $color-age-gate;
    font-family: $font-age-gate;
  }

  h2,
  .h2 {
    font-size: 18px;
    color: $color-age-gate;
    font-family: $font-age-gate;
    text-transform: uppercase;
    font-weight: bold;
  }
}
.age-gate-logo {
  max-width: 100%;
  height: auto;
  margin: 0 auto 25px auto;
}
.age-gate-button {
  margin-top: 15px;
  width: 150px;
  padding: 10px 30px;
  font-weight: bold;
  font-family: $font-age-gate;
  line-height: 1.4;
  border: 1px solid $color-age-gate-button;
  color: $color-age-gate-button;
  &:hover {
    color: $color-white;
    .age-gate-button-text {
      color: $color-white;
    }
  }
}
.age-gate-button-text {
  font-size: 12px;
  font-weight: normal;
  text-transform: uppercase;
  display: block;
  color: $color-age-gate-button;
}

.age-gate-links {
  display: flex;
  flex-direction: column;
  color: $color-age-gate-link;
  font-family: $font-age-gate;
  font-size: 11px;
  line-height: 1.75;

  &:not(:empty) {
    margin-top: 1.5rem;
  }

  a {
    color: $color-age-gate-link;
    text-decoration: underline;
    transition: all 0.3s ease;

    &:hover,
    &:focus,
    &:active {
      color: $color-age-gate-hover;
      text-decoration: underline;
    }
  }
}
.modal-backdrop {
  width: 100%;
  height: 100%;
}
