.aad {
    max-width: $max-aad-page-width;
    margin: $margin-aad-container-mobile;
    font-size: $font-size-body-m;
    background-color: $color-white;
    @include media-breakpoint-up(lg) {
        margin: $margin-aad-container;
    }

    .row,
    .row > div {
        padding: 0;
        margin: 0;
    }

    .account-aad {
        max-width: none;
        margin: 0;
    }

    &-message {
        padding: 1.5rem 2rem;
        background-color: $color-background-tertiary;

        .hero-heading {
            display: none;
        }

        h5,
        .h5 {
            color: $color-heading-tertiary;
        }

        p {
            margin-bottom: 0;
            color: $color-text-tertiary;
            line-height: 1.5;
        }

        a {
            color: $color-link-tertiary;

            &:hover,
            &:focus,
            &:active {
                color: $color-link-tertiary-hover;
            }
        }
    }
}

.account-aad {
    max-width: $max-aad-page-width;
    font-size: $font-size-body-m;
    padding: 1.5rem 2rem;
    margin: $margin-aad-container-mobile;
    background-color: $color-background-aad;
    color: $color-white;

    @include media-breakpoint-up(sm) {
        padding: 3rem 4rem;
    }

    @include media-breakpoint-up(lg) {
        margin: $margin-aad-container;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: $color-heading-primary;
        font-family: $font-family-heading-shop;
    }

    .ms-sign-in__sign-in-heading,
    .ms-sign-up__heading,
    .ms-password-reset-verification__heading,
    .ms-password-reset__heading,
    .ms-account-profile-edit__heading {
        @extend %text-shop-heading-primary;
        @extend %text-shop-heading-container;
        padding-top: 0;
        font-size: $font-size-heading-shop-l;
        text-transform: none !important;
    }

    .entry-item {
        margin-top: 1rem;
    }

    label {
        margin-bottom: 0;
    }

    input {
        @extend %input-shop;
        @include form-input-el();
    }

    .btn {
        width: 100%;
    }

    .sign-in-login,
    .sign-up-create,
    .sign-up-resend,
    .password-reset-verification-send,
    .password-reset-verification-verify,
    .password-reset-verification-edit,
    .password-reset-save,
    .account-profile-edit-save {
        margin-top: 1rem;
    }

    .sign-up-cancel,
    .password-reset-verification-cancel,
    .password-reset-verification-resend,
    .password-reset-verification-continue,
    .password-reset-cancel,
    .account-profile-edit-cancel {
        margin-top: 0.5rem;
    }

    .password-reset-verification-continue:disabled {
        display: none;
    }

    .sign-in-create-account-link {
        @extend .btn-secondary;
    }

    .sign-in-forget-password {
        @extend %link-shop;
        width: auto;
    }

    .error,
    .alert {
        display: none;
        padding: 0.5rem 1rem;
        margin: 0.5rem 0;

        &:not(:empty)[aria-hidden="false"] {
            display: block !important;
        }

        .alert-glyph {
            float: left;
            margin-right: 0.5rem;
        }

        p {
            margin-bottom: 0;
            line-height: 1.2;
        }
    }

    .sign-in-create-account-description {
        position: relative;
        padding: 1rem 0;
        margin: 1rem 0;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 1px;
            background-color: $color-border-primary;
        }

        &::after {
            content: "or";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0 0.5rem;
            background-color: $color-background-aad;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }

    .sign-in-social-accounts,
    .sign-in-create-account-heading {
        display: none;
    }
}
