.social-media {
  @extend %fa-icon;
  @extend .fab;
  font-family: "Font Awesome 6 Brands" !important;
  margin-right: 15px;

  &::before {
    display: inline-block;
    visibility: visible;
    width: 30px;
    font-size: 22px;
    text-align: center;
  }

  &-facebook::before {
    content: fa-content($fa-var-facebook-f);
  }

  &-instagram::before {
    content: fa-content($fa-var-instagram);
  }

  &-pinterest::before {
    content: fa-content($fa-var-pinterest);
  }

  &-twitter::before {
    content: fa-content($fa-var-x-twitter);
  }

  &-youtube::before {
    content: fa-content($fa-var-youtube);
  }

  &-yelp::before {
    content: fa-content($fa-var-yelp);
  }

  &-email::before {
    @extend .fas;
    content: fa-content($fa-var-envelope);
    font-family: "Font Awesome 6 Free" !important;
  }
}

.social-media-item {
  a,
  span {
    @extend .social-media;
    overflow: hidden;
    width: 30px;
    height: 30px;
    color: $color-white;
    display: block !important;
    line-height: 25px !important;
    font-weight: normal !important;
    transition: 0.15s color ease-in-out;

    .ms-footer__link__text {
      visibility: hidden;
    }

    &:focus {
      @include default-outline;
    }
    
    &:hover,
    &:focus,
    &:active {
      color: $color-link-footer-hover;
    }
  }

  &-facebook span {
    @extend .social-media-facebook;
  }

  &-instagram span {
    @extend .social-media-instagram;
  }

  &-twitter span {
    @extend .social-media-twitter;
  }

  &-pinterest span {
    @extend .social-media-pinterest;
  }

  &-youtube span {
    @extend .social-media-youtube;
  }

  &-yelp span {
    @extend .social-media-yelp;
  }

  &-email span {
    @extend .social-media-email;
  }
}

.footer-top {
  .footer-top:nth-child(3) {
    .ms-footer__list {
      .ms-footer__list-item:nth-child(2) {
        .ms-footer__list-item {
          display: inline-block;
        }
      }
    }
  }
}
