.shipping-service {
  .container-heading {
    @extend %text-shop-heading-secondary;
    @extend %text-shop-heading-container;
    border-color: $color-background-secondary;
    font-size: 32px;
    text-transform: uppercase;
  }

  > .row {
    padding: 0 2rem;

    > div:first-of-type {
      flex: 0 0 100%;

      @include media-breakpoint-up(lg) {
        flex: 1 1 25%;
        width: 25%;
        padding-left: 0;
      }

      .container-with-header-title {
        @extend %text-shop-heading-tertiary;
        font-size: 24px;
      }

      .content-block-with-title-title {
        @extend %text-shop-subheading;
        font-weight: 500;
        font-size: 18px;
        font-family: $font-family-heading;
        letter-spacing: 0;
      }
    }

    > div:last-of-type {
      flex: 0 0 100%;
      margin-top: 2rem;
      padding: 0 15px;

      @include media-breakpoint-up(lg) {
        flex: 1 1 75%;
        width: 75%;
        padding-right: 0;
        margin-top: 0;
        padding-left: 15px;
      }

      .ms-accordion-header-section-container h3,
      .ms-accordion-header-section-container .h3 {
        @extend %text-shop-heading-secondary;
        font-size: $font-size-heading-shop-m;
        text-transform: none;
      }

      .ms-accordion-header-section {
        margin-top: 2rem;

        @include media-breakpoint-up(sm) {
          display: flex;
          justify-content: space-between;
          margin-top: 0;
        }

        &-toggle-button-container button {
          @extend %link-shop;
          text-transform: lowercase;

          &:first-child {
            margin-right: 0.25rem;
          }

          &:last-child {
            margin-left: 0.25rem;
          }
        }
        &-toggle-button-container {
          line-height: 1.7;
        }
      }
      .ms-accordion-item {
        display: inline-block;
        width: 100%;
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
      .ms-accordion-item button {
        @extend %btn-default;
        padding: 1rem 0 0;
        margin: 2rem 0 0;
        border-top: 1px solid $color-silver;
        border-radius: 0;
        font-family: $font-family-link;
        font-size: $font-size-link-xxl;
        text-align: left;
        display: block;
        width: 100%;
        background-color: transparent;

        &:focus {
          box-shadow: none;
          outline-width: 0;
          @include media-breakpoint-up(lg) {
            outline-width: 1px;
          }
        }
      }

      .ms-accordion-item .content-block,
      .ms-accordion-item .content-item {
        padding: 0;
      }
    }
    .ms-accordion-item-content {
      h2,
      .h2 {
        font-size: 1.25rem;
      }
      ul,
      li {
        list-style: disc;
      }
      h5,
      .h5 {
        color: $color-codgray;
      }
    }
  }

  .container-with-header-title {
    margin-bottom: 2rem;
  }
  .content-block-with-title {
    &-text {
      font-size: 14px;
      h5,
      .h5 {
        color: $color-dovegray;
      }
    }
  }
  .container-with-header-slots {
    h3,
    .h3 {
      color: $color-dovegray;
    }
  }
  .content-block-with-title:not(:first-child) {
    margin-top: 1;
  }
}
