$msv-forgot-password-color: $color-link-primary;
$msv-sign-in-font-size: 14px;
$msv-sign-in-line-height: 20px;
$msv-sign-in-link-font-size: 12px;
$msv-sign-in-link-line-height: 26px;
$msv-sign-in-heading-font-size: 24px;
$msv-sign-in-heading-font-weight: normal;
$msv-sign-in-heading-line-height: 32px;
$msv-sign-in-button-margin-bottom: 60px;
$msv-sign-in-button-margin-top: 0.5rem;
$msv-sign-in-facebook-color: #3b5998;
$msv-sign-input-height: 32px;
$msv-sign-in-account-item-margin-top: 0.5rem;
$msv-sign-in-input-margin-top: 4px;
$msv-sign-in-input-padding: 8px;
$msv-sign-in-section-spacing: 25px;
$msv-sign-in-section-spacing-m: 40px;
$msv-sign-in-social-accounts-padding: 40px 0;
$msv-sign-in-social-button-padding: 8px 16px;
$msv-sign-in-social-accounts-button-margin-bottom: 20px;
$msv-sign-in-social-accounts-text-margin-left: 16px;
$msv-sign-in-link-margin-top: 8px;
$msv-sign-in-sign-up-description-margin-top: 20px;
$msv-sign-in-sign-up-description-margin-bottom: 32px;
$msv-sign-in-message-padding: 20px;
$msv-sign-in-message-margin-top: 20px;
$msv-sign-in-error-border: 1px solid $color-white;
$msv-sign-in-error-text-color: $color-white;
$msv-sign-in-container-margin: 50px 100px;
$msv-sign-in-container-margin-m: 20px;
.ms-sign-in {
  font-family: $font-family-body;
  max-width: none;
  margin: 0;
  padding: 1.5rem 2rem;
  @media screen and (min-width: 576px) {
    padding: 3rem 4rem;
  }

  &__container {
    display: block;
    width: 100%;
    margin: 0;
    label {
      margin-bottom: 0;
      color: $color-text-black;
      font-size: $font-size-body-m;
    }
  }
  &__item-error-text,
  &__page-error-text {
    margin: 0;
  }
  &__social-account-picture {
    display: inline-block;
    vertical-align: middle;
  }
  &__sign-in-section {
    flex: 1;
    h2,
    .h2 {
      margin-bottom: 2rem;
      display: block;
      font-family: $font-family-heading;
      color: $color-text-black;
      border-bottom: 1px solid $color-silver;
      padding: 1rem 0;
    }
  }
  &__sign-up-section {
    flex: 1;
    h2,
    .h2 {
      display: none;
    }
  }
  &__account-items {
    position: relative;
  }
  &__account-item {
    margin-top: $msv-sign-in-account-item-margin-top;
    &-input {
      @include form-input-el();
      height: 35px;
    }
    &-password {
      margin-bottom: 0.5rem;
    }
  }
  &__container-forget-password {
    text-align: center;
    margin-top: 0.5rem;
  }
  &__forget-password {
    color: inherit;
    font-family: $font-family-link;
    font-size: $font-size-body-s;
    display: inline-block;
    text-decoration: underline;
    &:hover {
      color: inherit;
    }
  }

  &__sign-in-button {
    @extend .btn-black;
    margin-top: $msv-sign-in-button-margin-top;
    width: 100%;
    padding: 0.6rem 0.8rem 0.6rem;
    border: none;
  }
  &__sign-up-link {
    @extend .btn-secondary;
    width: 100%;
    padding: 0.6rem 0.8rem 0.6rem;
    border: 1px solid;
    display: block;
    text-align: center;
    margin-top: 0.5rem;
  }
  &__social-accounts {
    display: none;
  }
  &__sign-up-description {
    margin: 0.75rem 0;
    display: none;
  }
  &__page-error,
  &__item-error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    display: none;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    p {
      line-height: 1.2;
    }
    p::before {
      font-family: $msv-icon-font-family;
      font-weight: $msv-solid-icon-weight;
      content: $msv-Warning;
      margin-right: 0.5rem;
    }
    &:not(:empty)[aria-hidden="false"] {
      display: block !important;
    }
  }
}

.account-aad {
  .aad {
    max-width: 600px;
    margin: -1rem auto;
    font-size: 14px;
    background-color: $color-white;
    @media screen and (min-width: 576px) {
      margin: 5rem auto 2rem;
      padding: 3rem 4rem;
    }
    .sign-in-show-password {
      margin: -36px 5px;
    }
  }
  .aad-message {
    padding: 1.5rem 2rem;
    background-color: $color-black;
    min-height: 0;
    .ms-content-block__title {
      display: none;
    }
    h5,
    .h5 {
      color: $color-link-primary-hover;
    }
    p {
      margin-bottom: 0;
      color: $color-white;
      line-height: 1.5;
      font-size: $font-size-body-m;
    }
    a {
      color: $color-link-primary;
      &:hover {
        color: $color-link-primary-hover;
      }
    }
  }
}
.sign-in-show-password {
  float: right;
  margin: -30px 5px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  background: none;
  border: none;
  .ms-sign-in & {
    margin: -35px 5px;
  }
}

.icon-show-password {
  @extend .fas;
  @extend .fa-eye;
}

.icon-hide-password {
  @extend .fas;
  @extend .fa-eye-slash;
}

.shop-account-page {
  main {
    margin: 0 15px;
  }
}
