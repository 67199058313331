@import "add-to-cart";
@import "add-to-wishlist";
@import "acclaim";
@import "alert";
@import "alert-custom";
@import "button";
// @import "carousel";
@import "cart-item";
@import "dropdown";
@import "error-page";
@import "icon";
@import "modal";
@import "price";
@import "product-card";
@import "promo-code";
@import "quickview";
@import "rating";
@import "slider";
@import "spacer";
@import "video-player";
@import "waiting";
@import "tooltip";
@import "event-list";
@import "modal";
@import "quickview";
