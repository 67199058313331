%link-nav-primary {
  border: none;
  background-color: transparent;
  color: $color-link-header;
  font-family: $font-family-link;
  font-size: $font-size-link-xl;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: $color-link-header-hover;
  }
}

%link-nav-secondary-menu {
  display: block;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.75rem 0;
  margin: 0;
  border-radius: 0;
  background-color: $color-black;
  position: absolute;
  transition: visibility 0s, opacity 0.5s linear;
  top: 100%;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  font-size: 1rem;
  color: #212529;
  text-align: center;
  list-style: none;
  background-clip: padding-box;
}

%link-nav-secondary {
  @extend %link-nav-primary;
  color: $color-link-header;
  font-size: $font-size-link-s;
  transition: none;

  &:hover,
  &:focus,
  &:active {
    color: $color-link-header-hover;
  }
}

%link-nav-tertiary {
  color: $color-link-footer;
  font-family: $font-family-body;
  font-size: $font-size-body-s;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: $color-link-footer-hover;
  }
}

%btn-marketing {
  padding: 0.5rem 1rem;
  border-color: $color-button-marketing-border;
  background-color: $color-button-marketing-background;
  background-image: linear-gradient(
    180deg,
    $color-button-marketing-background 0%,
    $color-button-marketing-background 40%,
    $color-button-marketing-background-hover 60%,
    $color-button-marketing-background-hover 100%
  );
  color: $color-button-marketing-text;
  font-family: $font-family-link;
  font-size: $font-size-link-l;
  text-transform: uppercase;
  transition: 0.3s all ease;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border-color: $color-button-marketing-border-hover;
    box-shadow: none;
    background-color: $color-button-marketing-background-hover;
    background-image: linear-gradient(
      180deg,
      lighten($color-button-marketing-background, 5) 0%,
      lighten($color-button-marketing-background, 5) 40%,
      lighten($color-button-marketing-background-hover, 5) 60%,
      lighten($color-button-marketing-background-hover, 5) 100%
    );
    color: $color-button-marketing-text-hover;
  }
}

%link-marketing {
  padding: 0;
  border: none;
  color: $color-link-primary;
  font-family: $font-family-link;
  font-size: $font-size-link-m;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    color: $color-darkgrey;
    text-decoration: none;
  }
}

%text-marketing-heading {
  font-family: $font-family-heading;
  font-size: $font-size-heading-l;

  @include media-breakpoint-up(sm) {
    font-size: $font-size-heading-xl;
  }
}

%text-marketing-subheading {
  font-family: $font-family-link;
  font-size: $font-size-link-m;
  letter-spacing: 1px;
  text-transform: uppercase;
}

%text-marketing-featured {
  font-family: $font-family-body;
  font-size: $font-size-body-xxl;
  line-height: 1.3;
}
%link-shop {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: $color-link-primary;
  font-family: $font-family-link;
  font-size: $font-size-link-m;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: $color-link-primary-hover;
  }
}

%link-shop-subfooter {
  @extend %link-marketing;
  color: $color-text-black !important;
  font-size: $font-size-link-m;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: $color-darkgrey !important;
  }
}

%link-shop-muted {
  @extend %link-shop;
  color: $color-text-black;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $color-text-black;
    text-decoration: none;
  }
}

%text-shop-heading-container {
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 2rem;
  border-bottom: 1px solid $color-border-primary;
}

%text-shop-heading-primary {
  color: $color-heading-primary;
  font-family: $font-family-heading-shop;
  font-size: $font-size-heading-shop-m;
  text-transform: uppercase;
}

%text-shop-heading-secondary {
  @extend %text-shop-heading-primary;
  font-size: $font-size-heading-shop-l;
  @include media-breakpoint-down(md){
    font-size: $font-size-heading-shop-m;
  }
}

%text-shop-heading-tertiary {
  @extend %text-shop-heading-secondary;
  font-size: $font-size-heading-shop-m;
  text-transform: none;
}

%text-shop-subheading {
  color: $color-heading-primary;
  font-family: $font-family-link;
  font-size: $font-size-link-m;
  font-weight: bold;
  letter-spacing: 0.85px;
  text-transform: uppercase;
}

%text-shop-strong {
  color: $color-text-primary;
  font-family: $font-family-body;
  font-size: $font-size-body-m;
  font-weight: bold;
  letter-spacing: 0;
  text-transform: none;
}

%link-header {
  border: none;
  background-color: transparent;
  color: $color-link-header;
  font-family: $font-family-link;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.1;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: $color-link-header-hover;
  }
}

%link-topheader {
  @extend %link-header;
  color: $color-link-header;
  font-size: 12px;
  font-weight: 400;

  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: $color-link-muted-hover;
  }

  @include media-breakpoint-up(sm) {
    font-size: 13px;
  }
}

%input-shop {
  min-height: 30px;
  padding: 0.25rem 0.5rem;
  border: 1px solid $color-input-border;
  border-radius: 0;
  background-color: $color-input-background;
  color: $color-input-text;
  font-family: $font-family-body;
  font-size: $font-size-body-m;
}

%input-shop-qty {
  @extend %input-shop;
  flex: none;
  width: 50px;
  height: 30px;
  padding: 0.25rem;
  text-align: center;
}

%col-properties {
  position: relative;
  width: 100%;
  padding: 0;
  @include media-breakpoint-up(sm) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
%form-control-partial {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

%form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $color-input-form-control;
  background-color: $color-white;
  background-clip: padding-box;
  border: 1px solid $color-input-form-control-border;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

%search-input {
  height: 25px;
  padding: 0.2rem 1.5rem 0.2rem 0.4rem;
  border: 0;
  border-radius: 0;
  background-color: $color-input-background;
  color: $color-dovegray;
  font-size: 12px;
  &:focus {
    outline: 0;
  }
}

%remove-btn-default {
  background: transparent;
  border: 0;
}

%btn-default {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

//Popover - Global
%popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: $font-family-body;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: $color-white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  $popover-border-radius: 0.3rem;
}
%input-marketing {
  border: 1px solid $color-background-secondary;
  border-radius: 0;
  background-color: $color-background-secondary;
  color: $color-background-secondary;
  font-family: $font-family-link;
  font-size: 14px;
}
%form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

%form-check-input {
  position: absolute;
  margin-top: 0.35rem;
  margin-left: -1.25rem;
  &[disabled] ~ .form-check-label,
  &:disabled ~ .form-check-label {
    color: $color-text-muted;
  }
}
%error-alert {
  position: relative;
  padding: $error-alert-padding;
  margin-bottom: $error-alert-margin-bottom;
  border: 1px solid transparent;
  border-radius: $error-alert-border-radius;
  color: $error-alert-color;
  background-color: $error-alert-background-color;
  border-color: $error-alert-border-color;
}

%warning-alert {
  @extend %error-alert;
  color: $warning-alert-color;
  background-color: $warning-alert-background-color;
  border-color: $warning-alert-border-color;
}

%success-alert {
  @extend %error-alert;
  color: $success-alert-color;
  background-color: $success-alert-background-color;
  border-color: $success-alert-border-color;
}
//remove native browser styling
%date-picker-input-override {
  -webkit-appearance: none;
  background-image: $asset-calendar-date;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 97.5% 50%;
  cursor: default;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button,
  &::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    opacity: 0;
  }
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    width: 15px;
  }
}

%select-input-override {
  -webkit-appearance: none;
  background-image: $asset-chevron-down;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 95% 50%;
}
