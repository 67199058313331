$msv-modal-width: 640px;
$msv-modal-padding: 20px;
$msv-modal-button-padding: 5px;
$msv-modal-horizontal-alignment-right: 1.75rem 1.5rem auto auto;
$msv-modal-horizontal-alignment-center: 1.75rem auto;
$msv-modal-horizontal-alignment-left: 1.75rem auto auto 1.5rem;
$msv-modal-vertical-alignment-top: flex-start;
$msv-modal-vertical-alignment-center: center;
$msv-modal-vertical-alignment-bottom: flex-end;
$msv-modal-z-index: 1050;

$msv-size-m: 360px;

.modal-open .msc-modal {
  overflow-x: hidden;
  overflow-y: auto;
  .msc-modal__dialog {
    max-width: 915px;
    max-height: 615px;
    padding: 0;
    outline: 0;
  }
}
.msc-modal {
  display: none;
  height: 100%;
  left: 0;
  overflow: hidden;
  outline: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $msv-modal-z-index;

  &.show .msc-modal__dialog {
    transform: none;
  }

  &.fade .msc-modal__dialog {
    transition: transform 0.3s ease-out;
  }

  & .msc-modal__dialog {
    align-items: $msv-modal-vertical-alignment-top;
    pointer-events: none;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50.1%) !important;
    max-width: 780px;
    min-height: 330px;
    margin: auto;
    background-color: #fff;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  & .msc-modal__content {
    background-clip: padding-box;
    background-color: $color-white;
    border: 1px solid $color-dovegray;
    display: flex;
    flex-direction: column;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%;

    & .msc-modal__header {
      padding: $msv-modal-padding;
      align-items: flex-start;
      border-color: transparent;
      display: flex;
      justify-content: space-between;
      position: relative;

      .msc-modal__title {
        @include font-content-xl();
        margin: 0;
        text-transform: none;

        @media screen and (max-width: $msv-size-m) {
          font-size: 22px;
        }
      }

      .msc-modal__close-button {
        @include add-icon($msv-x-shape);
        @include vfi();
        background-color: transparent;
        border: 0;
        color: $color-text-black;
        font-size: 1rem;
        line-height: 1;
        padding: $msv-modal-button-padding;
        position: absolute;
        right: 15px;
        top: 50%;
        opacity: 0.5;
        &:hover {
          opacity: 1;
          color: $color-grey;
        }
      }
    }

    & .msc-modal__body {
      padding: $msv-modal-padding 1px 0 0;
      @include media-breakpoint-up(lg) {
        padding: $msv-modal-padding 0 0 0;
      }
    }

    & .msc-modal__footer {
      padding: $msv-modal-padding;
    }
    .product-iframe {
      width: 759px;
      height: 560px;
    }
    .iframe-module-content {
      width: 100%;
      border: none;
      height: 100%;
    }
  }
}

.msc-modal__backdrop {
  background-color: $color-black;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $msv-modal-z-index - 10;

  &.show {
    opacity: 0.5;
  }
}

@media (prefers-reduced-motion: reduce) {
  .msc-modal.fade .msc-modal__dialog {
    transform: none;
  }
}

.youtube-modal.msc-modal {
  .msc-modal__body {
    height: 100%;
    padding: 0;
  }
  .msc-modal {
    &__dialog,
    &__content {
      background-color: transparent;
    }

    &__dialog {
      width: 100%;
      max-width: 900px;
      height: 90vh;
      max-height: 600px;

      &:focus {
        outline: none;
      }
    }

    &__content {
      height: 100%;
      border: 0;
    }

    &__header {
      padding: 0;
      margin-top: 4.6rem;
    }

    &__close-button {
      padding: 1rem;
      margin: -1.75rem -1rem -1rem auto;
      &:before {
        color: $color-white;
        font-size: 1.35rem;
        opacity: 1;
      }
    }
  }
  .modal-body {
    padding: 0;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}
