$msv-rating-count-border: 1px solid $color-black;

.msc-rating {
  position: relative;

  &__range {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    overflow: visible;
    color: transparent;
    background: transparent;
    border: 0 none;
    opacity: 0;
    z-index: -1;

    &:focus {
      + .msc-rating__group {
        border: 1px dashed $color-white;
        outline: 1px dashed $color-black;
      }
    }
  }

  &__star {
    @include add-icon($msv-FavoriteStarFill);
    color: $color-border-tertiary;
  }

  &__half-star {
    @include add-icon($msv-FavoriteStarFill);
    @include add-icon($msv-half-star, after);
    color: $color-silver;
    position: relative;

    &:after {
      color: $color-border-tertiary;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
  }

  &__empty-star {
    @include add-icon($msv-FavoriteStarFill);
    color: $color-silver;
  }

  &__star,
  &__half-star,
  &__empty-star {
    margin-right: 8px;
  }

  &__count {
    display: inline;
  }
  &__group {
    display: inline-block;
  }
}
