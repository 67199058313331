%breadcrumb-link {
    font-family: $font-family-link;
    font-size: $font-size-link-m;
    line-height: 1;
}

// @FIXME/vo: Change class name when breadcrumb class in custom module has been renamed.
.ms-breadcrumb {
    display: none;
    padding-bottom: 1rem !important;
    background: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(sm) {
        display: block;
    }

    &-cta {
        @extend %breadcrumb-link;
        vertical-align: initial;
        padding: 0 0.75rem;
        border: 0;
        color: $color-breadcrumb;
        text-decoration: underline;
        &:first-child {
            padding-left: 0;
        }
        &:hover,
        &:focus,
        &:active {
            color: $color-breadcrumb-hover;
        }
        &:focus{
            box-shadow: 0 0 0 0.2rem #706a66;
        }
        .call-to-action {
            &__glyph {
                display: none;
            }
        }
    }
    &-data {
        @extend %breadcrumb-link;
        display: inline-flex;
    }
    &-data-divider {
        color: $color-breadcrumb;
    }
    &-data-text {
        padding-left: 0.75rem;
        color: $color-breadcrumb-active;
    }
}