body {
  width: 100%;
  background-color: $color-background-body;
  color: $color-text-black;
  font-family: $font-family-body;
  font-size: $font-size-body-l;
  overflow-wrap: break-word;

  @include media-breakpoint-up(sm) {
      overflow-wrap: normal;
  }
}

// COL - from override 11/5/2022
p a {
  color: #9e7a44 !important;
  }
  p a:hover,
  p a:focus,
  p a:active {
  color: #6c4d39 !important;
  }