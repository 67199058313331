$search-max-width-mobile: 120px;
$search-max-width-desktop: 180px;
$search-form-padding: 0.175rem 0;
$search-form-margin: 0 1rem 0 0.55rem;
//Autosuggest Variables
$search-autoSuggest-results-item-font-size: 13px;
$search-autoSuggest-categoryResults-item-height: auto;
$search-autoSuggest-categoryResults-item-padding: 0 24px;
$search-autoSuggest-categoryResults-title-color: $color-background-secondary;
$search-autoSuggest-categoryResults-title-font-size: 14px;
$search-autoSuggest-categoryResults-title-line-height: 18px;
$search-autoSuggest-categoryResults-title-padding: 0.25rem 0;
$search-autoSuggest-keywordResults-item-height: 40px;
$search-autoSuggest-keywordResults-item-padding: 0;
$search-autoSuggest-picture-height: 60px;
$search-autoSuggest-picture-margin-right: 12px;
$search-autoSuggest-productResults-item-height: 48px;
$search-autoSuggest-productResults-item-font-size: 14px;
$search-autoSuggest-productResults-item-grid-columns-width: 52px;
$search-autoSuggest-productResults-title-color: $color-black;
$search-autoSuggest-productResults-title-font-size: 14px;
$search-autoSuggest-productResults-title-line-height: 18px;
$search-autoSuggest-productResults-padding: 0 0.3rem;
$search-autoSuggest-boxShadow: 0 0 5px rgba(0, 0, 0, 0.2);
$search-autoSuggest-top: 26px;
$search-autoSuggest-top-mobile: 35px;
$search-autoSuggest-item-padding: 0.75rem 0;
$search-autoSuggest-item-border: 1px solid $color-silver;
$search-autoSuggest-position-space: 9px;
$search-autoSuggest-background-color: $color-white;
$search-autoSuggest-border-color: $color-dovegray;
$search-autoSuggest-border-width: 0px;
$search-autoSuggest-max-width: 200px;
$search-autoSuggest-padding: 0.5rem 0.75rem;
$search-autoSuggest-hover-color: $color-gallery;

.ms-search {
  position: relative;
  padding: 0;
  @include breakpoint(xs) {
    max-width: $search-max-width-desktop;
  }
  @include media-breakpoint-up(lg) {
    max-width: $search-max-width-desktop;
  }
  //Search Field
  &__form {
    padding: 0;
    margin: 0 0.75rem;
  }
  &__searchForm {
    display: flex;
  }
  &__form-control {
    @extend %form-control;
    @extend %search-input;
    background-color: $color-white;
    padding-right: 2.5rem;
    font-size: $font-size-link-m;
    margin-top: 1px;
  }
  &__form-cancelSearchNoSumbit {
    @extend %remove-btn-default;
    margin-left: -25px;
    padding: 0 0.5rem;
    line-height: 0.8;
    color: $color-lightbrown !important;
    font-size: 12px;
    @include add-icon($msv-Cancel);
    &:hover,
    &:focus,
    &:active {
      color: $color-dovegray !important;
    }
  }
  &__form-submitSearch {
    display: none;
  }
  //Autosuggest
  &__autoSuggest {
    background: $search-autoSuggest-background-color;
    box-sizing: border-box;
    justify-content: flex-end;
    position: absolute;
    text-align: left;
    top: $search-autoSuggest-top;
    width: 100%;
    width: $search-autoSuggest-max-width;
    z-index: 1001;
    box-shadow: $search-autoSuggest-boxShadow;
    left: 0;

    &__keyword,
    &__product,
    &__category {
      padding: $search-autoSuggest-padding;
    }

    &__keyword,
    &__category {
      display: none;
    }
    .msc-autoSuggest {
      &__keywordResults,
      &__productResults,
      &__categoryResults {
        &-items {
          margin-bottom: 0;
          padding-left: 0;
        }
        &-item {
          padding: $search-autoSuggest-item-padding;
          border-bottom: $search-autoSuggest-item-border;
          font-size: $search-autoSuggest-results-item-font-size;
          a {
            @include vfi();
            padding: 0;
          }
          &:last-child {
            border: 0;
          }
        }
      }
      &__categoryResults-title {
        color: $search-autoSuggest-categoryResults-title-color;
        font-size: $search-autoSuggest-categoryResults-title-font-size;
        font-weight: bold;
        line-height: $search-autoSuggest-categoryResults-title-line-height;
        padding: $search-autoSuggest-categoryResults-title-padding;
        text-transform: uppercase;
      }
      &__productResults-item {
        @include vfi();
        display: flex;
        &-price {
          grid-column: 2 / 2;
          display: none;
        }
        &-text {
          grid-column: 2 / 2;
        }
        @include transition(0.3s background-color ease-in-out);
        a {
          @extend .no-underline;
          transition: none;
          &:hover {
            background-color: transparent;
          }
        }
      }
      &__productResults-title {
        color: $search-autoSuggest-categoryResults-title-color;
        font-size: $search-autoSuggest-categoryResults-title-font-size;
        font-weight: bold;
        line-height: $search-autoSuggest-categoryResults-title-line-height;
        padding: $search-autoSuggest-categoryResults-title-padding;
        text-transform: uppercase;
        display: none;
      }

      &__screen-reader {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
    }
  }
  a.msc-autoSuggest__productResults-item {
    img {
      display: none;
    }
  }
}
