.alert-custom {
    @extend .alert;
    border-radius: 0;

    &-success {
        @extend .alert-success;
    }

    &-danger {
        @extend .alert-danger;
    }

    &-warning {
        @extend .alert-warning;
    }

    &-light {
        @extend .alert-light;
        border-color: $color-input-background;
        background-color: $color-input-background;
        color: $color-input-text;
    }

    &-dark {
        @extend .alert-dark;
        border-color: $color-border-tertiary;
        background-color: $color-background-body;
        color: $color-white;
    }
}
