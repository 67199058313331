.mailing-list-popup {
  text-align: center;
  padding-top: 1rem;

  .ms-content-block {
    min-height: 0;
  }
  .mailing-list-popup-logo {
    margin-bottom: 2rem;
  }

  img {
    margin: 2rem auto 0;
    height: 100% !important;
    width: 100% !important;
    object-fit: contain !important;
    min-height: 100% !important;
  }

  .msc-modal__dialog {
    max-width: 500px !important;
    width: 90%;
    background: transparent;
    @include media-breakpoint-up(sm) {
      width: 70%;
    }
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .msc-modal__content {
    background-color: $color-white !important;
    border: 0;
    .msc-modal__header {
      padding: 0;
    }

    .msc-modal__body {
      padding: 0 3rem 1rem;
    }
    .msc-modal__close-button {
      right: 8px !important;
      top: 8px !important;
    }
  }

  .mailing-list-form-marketing-description {
    font-size: $font-size-body-m;
    .content-title {
      color: $color-text-muted;
      font-size: 1.5rem;
    }
    p {
      color: $color-text-black;
    }
  }

  .mailing-list-form-marketing-title,
  .mailing-list-form-marketing-subtitle {
    color: $color-link-primary;
  }
  .text-input-validation-error,
  .mailing-list-success-message p {
    @extend .alert-custom;
  }
  .mailing-list-success-message {
    color: $color-border-tertiary;
    font-size: $font-size-body-l - 1px;
    margin: 1.5rem 0;
    a {
      @extend %btn-default;
      @extend .btn-secondary;
      display: block;
      width: 100%;
      margin: 1rem auto -2rem;
      color: $color-text-black !important;
      @media (min-width: $breakpoint-xs) {
        width: 75%;
      }
    }
  }

  .text-input-validation-error {
    @extend .alert-custom-danger;
    padding: 0.5rem 0.75rem;
    margin-top: 0.5rem;
    font-size: $font-size-body-m;
  }

  .mailing-list-success-message p {
    display: inline-block;
  }

  .text-input-group {
    text-align: left;

    .form-control {
      @include form-input-el();
      background-color: $color-gallery !important;
    }

    label {
      margin-bottom: 0;
      color: $color-text-black;
      font-weight: bold;
    }
  }

  .input-group-mailing-list-button {
    @extend .btn;
    @extend .btn-black;
    margin: 1rem 0;
  }

  .popup-modal-close-text {
    display: inline-block;
    color: $color-text-black;
    font-size: $font-size-body-m;
    cursor: pointer;
    margin-bottom: 0.5rem;
    &:hover {
      border: none;
    }
  }
}
