$msv-checkout-guest-profile-input-margin-top: 4px;
$msv-checkout-guest-profile-input-height: 30px;
$msv-checkout-guest-profile-input-padding: 0.25rem 0.5rem;

$msv-checkout-guest-profile-input-label-width: 50%;

$msv-checkout-guest-profile-input-text-width: 100%;

$msv-checkout-guest-profile-error-max-width: 512px;
$msv-checkout-guest-profile-error-padding: 8px;
$msv-checkout-guest-profile-error-margin-bottom: 10px;
$msv-checkout-guest-profile-error-margin-top: 2px;
$msv-checkout-guest-profile-error-icon-margin-right: 8px;

.ms-checkout-guest-profile {
  display: flex;
  &__add-contact-info {
    width: 100%;
  }
  &__selected-email {
    font-size: 14px;
  }

  &__input-text {
    @include vfi();
    @include form-control-14hands;
    margin-top: $msv-checkout-guest-profile-input-margin-top;
  }

  &__input-label {
    font-size: $font-size-body-s;
    display: flex;
    align-items: flex-start;
    color: $color-text-black;
    margin-bottom: 0.5rem;
    line-height: 20px;
    width: $msv-checkout-guest-profile-input-label-width;
  }

  &__input-error {
    background-color: $color-link-primary;
    border: 1px solid $color-link-primary;
    max-width: $msv-checkout-guest-profile-error-max-width;
    padding: $msv-checkout-guest-profile-error-padding;

    margin-bottom: $msv-checkout-guest-profile-error-margin-bottom;
    margin-top: $msv-checkout-guest-profile-error-margin-top;
    &:before {
      @include msv-icon();
      content: $msv-IncidentTriangle;
      margin-right: $msv-checkout-guest-profile-error-icon-margin-right;
    }
    display: block;
    color: $color-white;
  }
}
.checkout-date-of-birth {
  display: flex;
  flex-direction: column;

  input {
    @include vfi();
    @include form-control-14hands;
    color: $color-text-black;
  }
  label {
    font-size: 14px;
    color: $color-text-black;
  }
}

 //prop65 warning
 .msc-p65-warning-icon > picture > img {
  max-width: 50px;
  text-align: right;
  }

  .msc-p65-warning-text  {
    padding: 3px;
    font-size: smaller;
    border: 2px solid black;
  }

.checkout-terms-and-conditions {
  font-size: 14px;
  label {
    display: block;
    margin-bottom: 0;
  }
}
