$footer-background-color: $color-white;
$footer-ms-footer-padding-xl: 3rem;
$footer-ms-footer-margin-left-mobile: 0px;
$footer-category-max-width: 200px;
$footer-category-padding-bottom-desktop: 40px;
$footer-category-padding-bottom-mobile: 20px;
$footer-heading-title-font-size: 18px;
$footer-heading-title-line-height: 40px;
$footer-list-item-icon-left: 10px;
$footer-list-item-icon-number: 3;
$footer-list-item-icon-top: 40px;
$footer-link-text-font-size: 13.5px;
$footer-link-text-line-height: 30px;
$footer-row-padding-top-mobile: 32px;
$footer-back-to-top-icon-font-size: 24px;
$footer-back-to-top-line-height: 24px;
$footer-back-to-top-height: 48px;
$footer-back-to-top-width: 48px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 24px;
$footer-categoty-back-to-top-max-width: 0px;
$bg-image-url: linear-gradient(
  0deg,
  $color-black 0%,
  lighten($color-black, 9) 40%,
  lighten($color-black, 12) 60%,
  lighten($color-black, 14) 80%,
  lighten($color-black, 12) 95%,
  lighten($color-black, 10) 100%
);
footer > .landing-footer {
 
  .ms-footer {
    
    .ms-back-to-top {
      @include vfi();
      border: 1px solid $color-silver;
      bottom: 24px;
      cursor: pointer;
      display: none;
      height: 48px;
      min-width: auto;
      opacity: 0;
      padding: 0;
      position: fixed;
      right: 24px;
      text-align: center;
      vertical-align: middle;
      width: 48px;
      .fa-arrow-up {
        color: $color-silver;
        font-size: 24px;
        left: 50%;
        line-height: 24px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        &:before {
          @include msv-icon();
          content: $msv-ChevronUp;
        }
      }
    }
    &__heading__title {
      font-size: 18px;
      font-weight: bold;
      line-height: 40px;
      display: none;
    }
    &__link__text {
      font-size: 13px;
      font-weight: bold;
      font-family: $font-family-body-landing;
      line-height: 30px;
      @include breakpoint(lg) {
        line-height: inherit;
      }
    }
    &__link {
      @include vfi();
      @include link-nav-primary;
      display: inline-flex;
      color: $color-white;
      img {
        @include vfi();
      }
    }
    & > * {
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      padding: 3rem;
      > * {
        max-width: 200px;
        padding-bottom: 40px;
        &:nth-child(4) {
          .ms-footer__list {
            display: flex;
            width: auto;
            padding:0;
          }
          .ms-footer__list-item:first-child {
            position: absolute;
            width: 100%;
          }
          .ms-footer__list-item:nth-child(1) {
            position: absolute;
            width: 100%;
          }
          .ms-footer__list-item:nth-child(2) {
            position: relative;
            top: $footer-list-item-icon-top;
            width: 100%;
          }
          @for $i from 3 to (3 + 2) {
            .ms-footer__list-item:nth-child(#{$i}) {
              left: 10 * ($i - 2);
              position: relative;
              top: $footer-list-item-icon-top;
              width: 100%;
            }
          }
        }
        &:last-child {
          max-width: 0;
        }
      }
    }
  }
  .landing-footer {
    .ms-footer__list {
      margin-bottom: 0;
      padding:0;
    }
    .ms-footer__list-item:not(:first-child) {
      margin-top: 0.5rem;
    }
    .ms-footer__link__text {
      font-family: $font-family-link-landing;
      font-size: 13px;
      font-weight: 700;
      line-height: 1.5;
    }
    .landing-copyright p {
      margin-bottom: 0;
    }
    .ms-footer__link:hover {
      color: #fff;
    }
  }
  @media screen and (min-width: $msv-breakpoint-l) {
    .landing-footer {
      max-width: 80%;
      .ms-footer__list {
        display: flex;
        .ms-footer__list-item:not(:first-child) {
          margin: 0 0 0 1rem;
        }
      }
    }
  }
  @media (max-width: $msv-breakpoint-m) {
    .ms-footer {
      margin-left: 0;
      > * {
        padding-top: 32px;
        > * {
          padding-bottom: 20px;
        }
      }
    }
  }
  @media (min-width: $msv-breakpoint-m) {
    .ms-footer {
      .ms-back-to-top[aria-hidden="false"] {
        display: block;
        opacity: 1;
        transform: translateX(0%);
      }
    }
  }
}

.landing-footer .col-12:first-child {
  .ms-footer__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    &-item:not(:first-child) {
      margin: 0;
      @include breakpoint(lg) {
      margin: 0 0 0 1rem;
      }
      .ms-footer__link__text {
        font-family: $font-family-body-landing;
        font-size: 13px;
        font-weight: bold;
      }
    }
    @include breakpoint(lg){
      flex-direction: row;
    }
  }
}

.landing-footer .col-12:nth-child(2) {
  .ms-footer__list {
    flex-direction: column;
    padding: 0;
    margin: 0;
    @include breakpoint(lg) {
      margin-bottom: 1rem;
    }
    &-item:nth-child(2) {
      margin: 0;
      @include breakpoint(lg) {
        margin: 0 0 0 1rem;
      }
    }
  }
}