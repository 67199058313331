footer,
.footer {
  background-color: $color-background-footer;
  background-image: linear-gradient(
    0deg,
    $color-black 0%,
    lighten($color-black, 9) 40%,
    lighten($color-black, 12) 60%,
    lighten($color-black, 14) 80%,
    lighten($color-black, 12) 95%,
    lighten($color-black, 10) 100%
  );
  color: $color-text-secondary;
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: $max-marketing-page-width;
  padding: 2rem;
  margin: 0 auto;
  .footer-enjoy {
    margin-top: 0.5rem;
  }
  .footer-enjoy img {
    width: 160px;
    height: auto;
  }
  .row {
    margin: 0;
  }

  // min-width: 1200px
  @include media-breakpoint-up(xl) {
    padding: 3rem 0;
  }
  .footer-copyright {
    font-size: 14px;
  }
  .footer-links,
  .footer-connect {
    h2,
    h4,
    h6 {
      margin-bottom: 1rem;
      color: $color-heading-secondary;
      font-family: $font-family-link;
      font-size: $font-size-link-m;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .ms-footer__link {
      @extend %link-nav-tertiary;
      text-transform: uppercase;

      &__text {
        font-size: 13px;
        @include breakpoint(md) {
          font-size: 12px;
        }
      }
    }
    .ms-text-block {
      a {
        color: $color-white !important;
        .shop & {
          font-family: $font-family-body;
          text-transform: uppercase;
        }
      }
    }
  }

  .col-12 {
    ul {
      line-height: 1.8;
      @include breakpoint(md) {
        line-height: 1.35;
      }
      padding: 0;
      margin: 0;
    }
    padding: 0;

    // min-width: 0 --> onwards
    &:first-child,
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: auto;
      padding-right: 0;
      margin-right: 0;
      max-width: unset;
      flex: 0 0 50%;
      max-width: 50%;
      border-right: none;
      p {
        font-size: 14px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        font-size: 13px;
        text-decoration: underline;
        &:hover{
          text-decoration: none;
        }
        .shop & {
          line-height: 1.65
        }
      }
    }
    &:nth-child(4) {
      border-right: none;
      ul {
        line-height: 1.5;
      }
    }
    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 2rem;
      @include breakpoint(md) {
        margin-top: 0;
      }
    }
    &:nth-child(5) {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      width: 100%;
      margin-top: 2rem;
      ul {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
      }
      li:first-child {
        flex: 0 0 100%;
      }
      li:last-child {
        // 0 --> onwards
        width: auto;
        // 992
        @include breakpoint(lg) {
          width: auto;
        }
      }
      @media screen and (min-width: 768px) {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }
      @media screen and (min-width: 992px) {
        max-width: 22%;
        flex: 0 0 22%;
        margin: 0 0 0 auto;
      }
    }
    &:nth-child(6) {
      margin-top: 2rem;
      .ms-footer__list {
        line-height: 2;
        @include breakpoint(md) {
          line-height: 1.5;
        }
      }
    }
    // min-width: 768px
    @media screen and (min-width: 768px) {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        flex: 0 0 25%;
        max-width: 25%;
        border-right: none;
        padding-right: 0;
        margin-right: 0;
      }
      &:nth-child(4) {
        border-right: none;
      }
    }
    // min-width: 992px

    @media screen and (min-width: 992px) {
      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        flex: 0 0 auto;
        max-width: unset;
        border-right: 1px solid $color-border-tertiary;
        padding-right: 2.5%;
        margin-right: 2.5%;
      }
      &:nth-child(4) {
        border-right: none;
      }
    }
    &:last-child {
      margin-top: 0.5rem;
    }
  }
  ul.footer-legal {
    display: flex;
    flex-direction: column;
    
    //768
    @include media-breakpoint-up(md) {
      display: block;
      padding-left: 0;
      margin-bottom: 0;
      width: 100%;
      li.footer-legal {
        display: inline-block;
        line-height: 1.5;
        .ms-footer__item {
          display: inline-block;
        }
      }
    }
  }
  li.footer-legal {
    display: flex;
    color: $color-white;
    line-height: 2;
    
    a {
      @extend %link-nav-tertiary;
      font-size: $font-size-body-l;
      @include breakpoint(md) {
        font-size: $font-size-body-m;
      }
    }

    .ms-text-block {
      display: inline-block;
      a {
        color: $color-link-footer !important;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
          color: $color-link-footer-hover !important;
        }
      }
      p {
        margin-bottom: 0;
      }
    }

    &::before {
      @include breakpoint(md) {
        content: "•";
        margin: 0 0.5rem;
      }
    }

    &:first-child {
      &::before {
        content: none;
      }
    }
  }

  .footer-copyright {
    .copyright {
      color: $color-dovegray;
      margin-top: 0.15rem;
    }
  }

}
ul.mailing-list-footer {
  h5,
  .h5 {
    color: $color-white;
  }
  .btn-secondary {
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      color: $color-link-secondary !important;
      border-color: $color-link-secondary !important;
      text-decoration: none;
    }
  }
}
