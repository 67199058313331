.order-placed {
  .ms-order-details__loading {
    padding-left: 1rem;
  }
  div {
    padding: 0;
    margin: 0;
  }

  .container-heading {
    @extend %text-shop-heading-container;
    @extend %text-shop-heading-primary;
  }

  .thankyou {
    padding: 0 1rem 1rem;
  }

  .ms-order-confirmation {
    padding: 1.5rem 2rem;
    background-color: $color-background-secondary;

    &__heading {
      @extend %text-shop-heading-secondary;
      margin-bottom: 1rem;
    }

    &__btn-keep-shopping {
      @extend %btn-default;
      @extend .btn-black;
    }
    &__order-information {
      > * {
        display: inline-block;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    &__alert {
      display: flex;
      width: 100%;
      margin-bottom: 1rem;
    }

    &__groups,
    &__order-summary,
    &__payment-methods {
      display: none;
    }
    &__loading {
      display: block;
      margin-bottom: 1rem;
    }
  }

  .ms-order-details {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 1rem 0;
    @include media-breakpoint-up(sm) {
      padding: 2rem 0 0;
    }

    > * {
      flex: 0 0 100%;
      order: 2;

      &:not(:first-child) {
        margin-top: 1rem;
        @include media-breakpoint-up(lg) {
          margin-top: 0;
        }
      }
    }
    &__heading {
      display: none;
    }
  
    p {
      margin-bottom: 0;
    }
    //First Item
    &__order-information {
      margin-bottom: 0.5rem;
      // order: 1;
      margin-top: 0 !important;
      text-align: left;
      @include media-breakpoint-up(lg) {
        text-align: right;
      }
    }

    &__order-information-channel-reference-id {
      display: block;
      margin: 0;
      font-size: $font-size-body-m;
      line-height: 1.5;
    }

    &__order-information-sales-id {
      padding-right: 0.25rem;
      border: 0;
    }

    &__order-information-count,
    &__order-information-amount {
      padding: 0 0.25rem;
      border-left: 1px solid $color-silver;
      border-right: 0;
    }

    &__order-information-created-date {
      padding: 0 0.25rem 0 0;
      border-right: 0;
    }

    .ms-order-details__order-information-sales-id + .ms-order-details__order-information-created-date {
      padding: 0 0.25rem;
      border-left: 1px solid $color-silver;
    }

    //Second Item
    &__groups {
      border: none;
      //order: 2;
      @include media-breakpoint-up(lg) {
        margin-top: -3.2rem !important;
      }
    }
    //2nd item inner
    &__group {
      display: flex;
      flex-wrap: wrap;

      &:not(:first-child) {
        margin-top: 1.5rem;
      }

      > * {
        flex: 1 1 100%;
        max-width: 100%;
      }
    }
    &__sales-lines {
      order: 2;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid $color-border-secondary;
      @include media-breakpoint-up(sm) {
        margin-bottom: 1rem;
      }
      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: 1.5rem;
      }
    }

    &__sales-line {
      padding: 1rem 1rem 1rem 0;
      @include media-breakpoint-up(md) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include media-breakpoint-up(xl) {
        flex: 0 0 33.3%;
        max-width: 33.3%;
      }

      .msc-cart-line {
        display: flex;
        &__product-image {
          flex: 0 0 60px;
          max-width: 60px;
          position: relative;
          img {
            max-height: 90px;
            max-width: 50px;
            z-index: 100;
            position: absolute;
            top: 0.3rem;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 0.15rem;
            transition: all 0.3s linear;
            &:hover {
              max-width: 300px;
            }
          }
        }
        &__content {
          flex: 0 0 88%;
          max-width: 88%;
          margin-left: 60px;
          @include media-breakpoint-up(md) {
            flex: 0 0 80%;
            max-width: 80%;
            margin-left: unset;
          }
          @include media-breakpoint-up(xl) {
            flex: 0 0 90%;
            max-width: 90%;
          }
          flex-direction: column;
        }
        &__product-title {
          color: $color-link-primary;
          font-weight: bold;
          font-size: $font-size-body-m;
          &:hover,
          &:focus {
            color: $color-link-primary-hover;

          }
        }
        &-item-product-discount {
          display: none;
        }
        &__quantity {
          position: static;
          display: inline-block;
          width: 100%;

          label {
            margin-bottom: 0;
            font-size: $font-size-body-m;
            &:after {
              content: ":";
            }
          }
          span {
            margin-left: 0.25rem;
            font-size: $font-size-body-m;
          }
        }
        &__product-price {
          position: static;
          display: inline-block;
          width: 100%;
          text-align: left;
          .msc-price__actual {
            font-size: $font-size-body-m;
            line-height: 1;
            color: $color-dovegray;
          }
        }
      }
    }

    &__sales-status {
      font-style: italic;
      display: none;
      &::before {
        content: "Status: ";
        font-weight: bold;
        font-style: normal;
      }
    }
    .salesLine {
      .ms-order-details__sales-status {
        display: inline-block;
        margin-left: 60px;
      }
    }

    &__sales-line-btn-buy-it-again {
      margin-top: 0.3rem;
      margin-left: 60px;
      @extend %btn-default;
      @extend .btn-secondary;
    }

    &__address-header,
    &__payment-methods-title {
      font-weight: bold;
      text-transform: capitalize;
    }
    &__group-delivery {
      order: 1;
    }

    &__group-delivery-heading-custom {
      display: none;
    }
    &__address-full-address {
      display: flex;
      flex-direction: column;
    }

    &__order-summary {
      order: 4;
      @include media-breakpoint-up(sm) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include media-breakpoint-up(lg) {
        flex: 0 0 35%;
        max-width: 35%;
      }
      @include media-breakpoint-up(xl) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      * {
        display: flex;
        justify-content: space-between;
      }
      &-line-total-amount {
        border-top: 1px solid;
        padding-top: 0;
        * {
          font-weight: bold;
        }
      }
    }
    &__payment-methods {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 1rem;
      width: auto;
      order: 3;
      flex: 0 0 100%;
      max-width: 100%;
      @include media-breakpoint-up(sm) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include media-breakpoint-up(lg) {
        flex: 0 0 65%;
        max-width: 65%;
      }
      @include media-breakpoint-up(xl) {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
  }
}
