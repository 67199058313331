.shop {
  .ms-account-profile {
    border-bottom: 1px solid $color-border-primary;
    &__section {
      @extend %col-properties;
      display: flex;
      flex-wrap: wrap;
      padding: 0 2rem;
      margin-bottom: 0.5rem;
    }

    &__section-name {
      margin-bottom: 1.3rem;
    }

    &__section-heading {
      @extend %text-shop-strong;
      margin-bottom: 0;
      line-height: 1;
    }
    &__section-description {
      margin-left: 0.5rem;
      margin-bottom: 0;
      line-height: 1;
    }
    &__section-links {
      width: 100%;
      margin-top: 0.5rem;
      line-height: 2;
      font-size: $font-size-body-s;
    }
    &__section-preferences {
      display: none;
    }
    .ms-account-loyalty-tile__heading {
      padding: 0.8rem 1rem 0.5rem;
      margin-bottom: 2rem;
      background-color: $color-grey;
      @extend %text-shop-heading-secondary;
      font-size: $font-size-body-l;
    }
  }
}
