$msv-checkout-button-font-size: 14px;
$msv-bacttoshoping-button-font-size: 12px;
$msv-checkout-button-font-style: $font-family-link;
$msv-checkout-button-height: 32px;
$msv-checkout-button-line-height: 20px;
$msv-checkout-button-margin: 15px auto;
$msv-checkout-button-padding: 6px 16px 7px;
$msv-checkout-button-text-align: center;
$msv-checkout-button-width: 100%;

$divider-vertical-margin: 20px;
$cart-items-right-margin: 40px;
$cart-empty-image-width: 157px;
$summary-min-width: 320px;
$summary-max-width: 480px;

$cart-heading-padding: 1rem 0;
$cart-heading-margin-bottom: 2rem;
$cart-heading-font-size: 32px;

$cart-cartline-wrapper-margin-right: 40px;
$cart-cartline-wrapper-margin-bottom: 1.5rem;

$cart-cartline-line-padding-right: 20px;

$cart-content-margin-left: 200px;
$cart-line-card-height: 220px;
$cart-cartline-line-product-padding-bottom: 10px;
$cart-cartline-line-product-price-padding-top: 10px;
$cart-cartline-line-product-price-margin-left: 0px;
$cart-cartline-line-remove-link-margin-left: 150px;
$cart-order-summary-asterix-font-size: 13px;

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-cart__btn-backtoshopping,
.msc-promo-code__apply-btn {
  display: block;
  font-size: $msv-bacttoshoping-button-font-size;
  font-style: $msv-checkout-button-font-style;
  line-height: $msv-checkout-button-line-height;
  padding: $msv-checkout-button-padding;
  text-align: $msv-checkout-button-text-align;
  line-height: 10.5px;
}

.msc-cart__btn-backtoshopping {
  @extend .btn-secondary;
  @extend .no-underline;
  padding: 0.5rem 0.8rem 0.6rem !important;
  text-shadow: none;
  font-size: 13px;
  border-width: 1px;
  border-style: solid;
  // border: 1px solid $color-link-primary;
  // color: $color-link-primary;
  line-height: 1.5;
}

.msc-cart__btn-guestcheckout {
  margin-top: 5px;
  &:focus {
    @include default-outline;
  }
}

.msc-cart__btn-checkout {
  @extend .btn-black;
  // border: 1px solid $color-link-primary;
  // background-color: $color-link-primary;
  // color: $color-white;
  font-size: 13px;

  // &:hover,
  // &:focus,
  // &:active,
  // &:not(:disabled):not(.disabled):active,
  // &:not(:disabled):not(.disabled):focus,
  // &:not(:disabled):not(.disabled):active:focus {
  //   border-color: $color-link-primary-hover;
  //   background-color: $color-link-primary-hover;
  //   color: $color-white;
  // }
}

.msc-cart__empty-cart {
  width: fit-content;
  a {
    @extend .btn-black;
  }
}

.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-cart__btn-backtoshopping {
  cursor: pointer;
}

.ms-cart {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;

  .msc-alert-danger {
    @extend %error-alert;
    width: 100%;
    font-weight: normal;
    font-size: 14px;
    margin-top: -0.75rem;
    padding: 0.5rem 0.75rem;
    p {
      @include add-icon($msv-Warning);
      margin-bottom: 0;
      line-height: 1.2;
      &::before {
        margin-right: 0.5rem;
      }
    }
  }

  .msc-order-summary-wrapper {
    background: none;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  .msc-order-summary {
    &__line-sub-total,
    &__line-total {
      .msc-order-summary__label {
        color: $color-text-black;
        font-size: $font-size-body-l;
        font-weight: bold;
        text-transform: none;
      }
    }
  }

  .msc-order-summary__heading {
    display: none;
  }

  .cart-order-summary-asterix {
    font-style: italic;
    font-size: $cart-order-summary-asterix-font-size;
    margin-bottom: 0.5rem;
  }
  
  .msc-price__actual {
    font-size: $msv-checkout-button-font-size;
  }
}

@media (max-width: 768px) {
  .ms-cart {
    display: block;
  }
}

.msc-cartline-wraper {
  width: 100%;
  flex-basis: 100%;
  table {
    width: 100%;
    th {
      font-weight: inherit;
    }
    td {
      flex-wrap: wrap;
    }
  }
}

.ms-cart .msc-cart-line {
  position: relative;

  &__product-image {
    position: absolute;
  }

  &__remove-item {
    color: $color-lightbrown;
    margin: 0.375rem 0.5rem;
    background-color: transparent;
    border: 1px solid transparent;
    transition: ease-in-out color 0.15s;

    &::before {
      @extend %fa-icon;
      @extend .fas;
      content: fa-content($msv-Remove-cartItem);
      font-size: 16px;
    }
    &:hover,
    &:active {
      color: $color-link-muted-hover;
    }
    &:focus{
      border: 1px solid $color-black;
    }
  }
}

.checkout-btn-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: $breakpoint-s) {
    display: block;

    a {
      width: 100%;
    }
  }

  a {
    display: inline-flex;
    justify-content: center;
    margin-top: 0.5rem;
  }

  .msc-cart__btn-guestcheckout {
    flex-basis: 100%;
    justify-content: flex-start;
    color: $color-dustygray;
    font-size: 14px;
    padding-left: 0;
  }

  .msc-cart__btn-checkout {
    margin-left: auto;
    padding: 0.7rem 0.8rem 0.8rem !important;
    line-height: 13.5px;
  }
}

.msc-cart {
  &__heading {
    width: 100%;
    padding: $cart-heading-padding;
    margin-bottom: $cart-heading-margin-bottom;
    color: $color-dovegray;
    font-family: $font-family-heading;
    border-bottom: 1px solid $color-silver;
    text-transform: uppercase;
    font-size: 24px;
  }

  &-price {
    text-align: right;
  }
}

@media screen and (min-width: $msv-breakpoint-m) {
  .ms-cart {
    flex-direction: row;
  }

  .msc-cartline-wraper {
    width: 100%;
    flex-basis: 100%;
  }
}

.msc-cart-lines-item {
  .msc-empty_image {
    @include image-placeholder($cart-empty-image-width);
  }
}

@media screen and (max-width: $msv-breakpoint-m) {
  .msc-cart-lines-item {
    .msc-cart-line {
      &__content {
        display: block;
      }

      &__product {
        padding-bottom: $cart-cartline-line-product-padding-bottom;
      }

      &__product-price {
        justify-content: flex-end;
        padding-top: $cart-cartline-line-product-price-padding-top;
        text-align: left;
      }

      &_add_to_wishlist {
        display: contents;
      }
    }
  }
}

.msccart-to-shoping {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.msc-guest-shoping {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: $color-dustygray;
}

@media screen and (max-width: $msv-breakpoint-m) {
  .msc-cart-line {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .ms-cart .msc-cart-line__product-image {
    position: relative;
    margin: 0;
    text-align: center;
    width: 100%;
  }

  .msc-cart-lines-item .msc-cart-line__content {
    margin: 10px 0 0;
  }

  .msc-promo-code__group {
    flex-direction: column;
  }

  .msccart-to-shoping {
    flex-direction: row;
    flex-wrap: wrap;
  }
  // max-width 768
  .msc-cart__btn-backtoshopping {
    padding: 8px;
    font-size: 13px;
  }
}

.cart-lineitems-header {
  font-size: 13px;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
  font-weight: bold;
  color: $color-codgray;
  letter-spacing: 0.85px;
}

.cart-lineitems-header,
.cart-lineitems-container .cart-line-item-product {
  display: flex;

  .header-items,
  .cart-line-item-product-details {
    width: 50%;
    @include media-breakpoint-up(sm) {
      width: 55%;
    }

    @include media-breakpoint-up(md) {
      width: 50%;
      align-items: center;
      display: flex;
    }
  }

  .cart-line-item-product-details {
    @include media-breakpoint-up(md) {
      padding-left: 1rem;
    }

    .cart-line-item-product-heading {
      font-weight: bold;
      margin-top: 0.2rem;
    }
  }

  .header-qty,
  .cart-line-item-price-quantity {
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;

    @include media-breakpoint-up(md) {
      width: 20%;
    }
  }
  .header-qty {
    width: 30%;
    @include media-breakpoint-up(sm) {
      width: 25%;
    }
  }

  .cart-line-item-price-quantity {
    .quantity {
      width: auto;
    }

    .quantity-input {
      @extend %input-shop-qty;
    }

    .cart-line-item-actions-remove-button {
      color: tomato-muted;

      &:hover,
      &:active,
      &:focus {
        color: tomato-muted-hover;
      }

      &::before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-times-circle);
        font-size: $font-size-icon-m;
      }

      .call-to-action__string {
        display: none;
      }
    }
  }

  .header-unitprice,
  .cart-line-item-product-price {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
      width: 20%;
    }
  }

  .cart-line-item-product-price {
    flex-direction: column;
    align-items: flex-start;

    .cart-line-item-product-discount > *:first-child {
      display: none;
    }

    .cart-line-item-product-discount-price::before {
      content: "Discount:";
    }
  }

  .header-total,
  .cart-line-item-price {
    justify-content: flex-end;
    align-items: center;
    width: 20%;
    display: flex;

    @include media-breakpoint-up(md) {
      width: 10%;
    }
  }

  .cart-line-item-price {
    width: 10%;

    @media only screen and (max-width: $breakpoint-s) {
      width: 20%;
    }

    .cart-line-item-price-savings-text {
      text-align: right;
    }
  }
}

.cart-lineitems-container {
  padding: 0 0 0.5rem;
  margin: 1rem 0;
  list-style-type: none;

  .cart-line-item {
    padding: 1rem 0;
    border-bottom: 1px solid $color-background-secondary;
  }
}

.cart-line-item-product {
  .msc-cart-line__product-price {
    width: 20%;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    text-align: left;

    @media only screen and (max-width: $breakpoint-s) {
      display: none !important;
    }
  }
}
.promo-code-wrap {
  padding: 1rem;
  margin-top: 3rem;
  background-color: $color-grey;
  width: 100%;
  p {
    margin-bottom: 0;
  }
  .msc-alert-danger {
    @extend %error-alert;
    @include add-icon($msv-Warning);
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    margin-top: 0.5rem;
    &::before {
      margin-right: 0.5rem;
    }
  }
  .msc-promo-code__input-box {
    @extend %input-shop;
    background-color: #fff;
    width: 100%;
    line-height: 1.8;
    border-color: $color-lightbrown;
  }

  .msc-promo-code__apply-btn {
    @extend .btn-black;
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 1.4;
  }

  .promo-code-coupon-container {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid $color-dovegray;
    color: tomato;
    font-size: $font-size-body-l;
    font-weight: bold;
  }

  .promo-code-coupon-subheading {
    margin-bottom: 0.5rem;
    color: $color-dovegray;
    font-size: $font-size-body-xs;
    font-style: italic;
  }

  .promo-code-coupon-container,
  .promo-code-line-container {
    display: flex;
    justify-content: space-between;
  }

  .promo-code-line-cta-remove {
    padding: 0;
    font-size: $font-size-body-s;
  }

  .promo-code-divider,
  .promo-code-coupon-price {
    display: none;
  }
  .msc-promo-code__line-container {
    border: none !important;
  }
}
