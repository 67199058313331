//==============================================================//
//----------         Wineclub Variables Start         ----------//

$wineclub-page-max-width: 1600px;
$wineclub-page-space-l: 90px;
$wineclub-page-space-m: 60px;
$wineclub-page-space-s: 30px;
$wineclub-space-l: 40px;
$wineclub-space-m: 30px;
$wineclub-space-s: 20px;

$wineclub-font-family-heading: $font-family-heading;
$wineclub-font-family-body: $font-family-body;
$wineclub-font-family-link: $font-family-link;

$wineclub-color-bg-light: $color-grey;
$wineclub-text-color: $color-text-black;
$wineclub-color-light: $color-white;
$wineclub-button-color-primary: $color-text-black;
$wineclub-main-border-color: #ECEBEB;

$wineclub-item-max-width: 420px;
$wineclub-item-space: 30px;
$wineclub-item-color: $color-text-black;
$wineclub-item-color-accent: $color-grey;
$wineclub-item-color-border: #D5DBE1;
$wineclub-item-button-size: 55px;

$wineclub-font-size-modal-heading: 22px;

%fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    @-webkit-keyframes fadeInDown {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, -40px, 0);
            transform: translate3d(0, -40px, 0);
        }

        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }

    @keyframes fadeInDown {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(0, -40px, 0);
            transform: translate3d(0, -40px, 0);
        }

        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }
}

@mixin main-heading-font-style($fontSize: 24px, $lineHeight: 1.33, $fontWeight: 400) {
    font-family: $wineclub-font-family-heading;
    font-size: $fontSize;
    line-height: $lineHeight;
    font-weight: $fontWeight;
    color: $wineclub-text-color;

    @include wc-breakpoint(lg) {
        font-size: ($fontSize + 12px);
        line-height: ($lineHeight + 0.17);
    }

    @include wc-breakpoint(xl) {
        font-size: ($fontSize + 18px);
        line-height: $lineHeight;
    }
}

@mixin heading-font-style($fontSize: 22px, $lineHeight: 1.45, $fontWeight: 400) {
    font-family: $wineclub-font-family-heading;
    font-size: $fontSize;
    line-height: $lineHeight;
    font-weight: $fontWeight;
    color: $wineclub-text-color;

    @include wc-breakpoint(md) {
        font-size: ($fontSize + 2px);
        line-height: ($lineHeight - 0.12);
    }

    @include wc-breakpoint(lg) {
        font-size: ($fontSize + 4px);
        line-height: ($lineHeight + 0.09);
    }

    @include wc-breakpoint(xl) {
        font-size: ($fontSize + 6px);
        line-height: ($lineHeight - 0.02);
    }
}

@mixin subheading-font-style($fontSize: 14px, $lineHeight: 1.71, $fontWeight: 400) {
    font-family: $wineclub-font-family-heading;
    font-size: $fontSize;
    line-height: $lineHeight;
    font-weight: $fontWeight;
    color: $wineclub-text-color;
    letter-spacing: 0.15em;
    text-transform: uppercase;

    @include wc-breakpoint(lg) {
        font-size: ($fontSize + 2px);
        line-height: ($lineHeight - 0.21);
    }

    @include wc-breakpoint(xl) {
        font-size: ($fontSize + 4px);
        line-height: ($lineHeight - 0.38);
    }
}

@mixin text-font-style($fontSize: 14px, $lineHeight: 1.71, $fontWeight: 400) {
    font-family: $wineclub-font-family-body;
    font-size: $fontSize;
    line-height: $lineHeight;
    font-weight: $fontWeight;
    color: $wineclub-text-color;

    @include wc-breakpoint(md) {
        font-size: ($fontSize + 1px);
    }

    @include wc-breakpoint(lg) {
        font-size: ($fontSize + 2px);
    }
}

@mixin vfi-focus($color: $wineclub-text-color) {
    &:focus-visible {
        outline: 1px dotted $color;
    }
}

@mixin wineclub-link {
    @include vfi-focus();
    font-family: $wineclub-font-family-link;
    color: $wineclub-text-color;
    text-decoration: underline;
    transition: ease-in-out 0.2s color;

    &:hover,
    &:focus {
        color: lighten($color: $wineclub-text-color, $amount: 10%);
    }
}

@mixin btn($type) {
    @include vfi-focus();
    font-family: $wineclub-font-family-link;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    min-width: 160px;
    padding: 15px 35px;
    font-size: 14px;
    line-height: 1.71;
    width: 100%;

    @include wc-breakpoint(btn) {
        width: auto;
    }

    @include wc-breakpoint(xl) {
        font-size: 16px;
        line-height: 1.5;
    }

    @if ($type =='primary') {
        color: $wineclub-color-light;
        border: 1px solid $wineclub-button-color-primary;
        background-color: $wineclub-button-color-primary;

        &:before,
        &:after {
            color: $wineclub-color-light;
        }
    }

    @else if ($type =='secondary') {
        color: $wineclub-button-color-primary;
        border: 1px solid $wineclub-button-color-primary;
        background-color: $wineclub-color-bg-light;

        &:before,
        &:after {
            color: $wineclub-button-color-primary;
        }
    }
}

%btn-selection {
    @include add-icon($msv-Selected);
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        width: 0;
        height: 0;
        font-size: 10px;
        line-height: 1.5;
        padding: 0;
        border: 2px solid $wineclub-color-light;
        border-radius: 100px;
        transition: ease 0.4s opacity, ease 0.4s width, ease 0.4s height, ease-out 0.2s margin-right;
    }

    &:disabled {
        &::before {
            opacity: 1;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            padding: 1px 3px;
        }
    }
}

@mixin add-page-space() {
    padding-left: $wineclub-page-space-s;
    padding-right: $wineclub-page-space-s;

    @include wc-breakpoint(md) {
        padding-left: ($wineclub-page-space-s + 10px);
        padding-right: ($wineclub-page-space-s + 10px);
    }

    @include wc-breakpoint(lg) {
        padding-left: $wineclub-page-space-m;
        padding-right: $wineclub-page-space-m;
    }

    @include wc-breakpoint(xl) {
        padding-left: $wineclub-page-space-l;
        padding-right: $wineclub-page-space-l;
        max-width: $wineclub-page-max-width;
        margin-left: auto;
        margin-right: auto;
    }
}

//----------          Wineclub Variables End          ----------//
//==============================================================//

.join-club {
    .row {
        margin: 0;
    }

    .col-12 {
        padding: 0;
    }

    &.modal-open {
        position: fixed;
        left: 0;
        right: var(--windowScrollBarWidth);
        top: var(--windowScrollY) !important;

        #attentive_overlay {
            display: none;
        }
    }
}

.wineclub-hero {
    position: relative;

    .ms-content-block__image {
        max-height: 190px;
        overflow: hidden;

        @include wc-breakpoint(md) {
            max-height: 270px;
        }

        @include wc-breakpoint(lg) {
            max-height: 340px;
        }

        img {
            object-position: center;
            height: auto;
        }
    }

    .ms-content-block__details {
        padding: $wineclub-space-s $wineclub-space-m;
        text-align: center;
        background-color: $wineclub-color-bg-light;

        @include wc-breakpoint(md) {
            padding: $wineclub-space-s $wineclub-space-l;
        }

        @include wc-breakpoint(lg) {
            position: absolute;
            top: 50%;
            left: 0;
            max-width: 58.5%;
            transform: translateY(-50%);
            padding: 0;
            margin: 0 $wineclub-page-space-m;
            text-align: left;
            background-color: transparent;
        }

        @include wc-breakpoint(xl) {
            margin: 0 $wineclub-page-space-l;
            max-width: 33.5%;
        }

        .ms-content-block__title {
            @include main-heading-font-style();
            margin-bottom: 5px;

            @include wc-breakpoint(lg) {
                color: $wineclub-color-light;
                margin-bottom: 15px;
            }
        }

        .ms-content-block__text {
            @include text-font-style();
            margin-bottom: 0;
            font-family: $font-family-arial;

            p {
                margin-bottom: 0;
            }

            @include wc-breakpoint(lg) {
                color: $wineclub-color-light;
            }
        }
    }
}

.wineclub-summary {
    margin: 30px auto;
    max-width: 540px;
    text-align: center;
    padding: 0 30px;

    @include wc-breakpoint(lg) {
        max-width: 58.6%;
        margin: 40px auto;
    }

    @include wc-breakpoint(xl) {
        max-width: 54.2%;
    }

    .ms-content-block__title {
        @include main-heading-font-style();
        margin-bottom: 10px;

        @include wc-breakpoint(md) {
            font-size: 28px;
            line-height: 1.43;
        }

        @include wc-breakpoint(lg) {
            font-size: 32px;
            line-height: 1.5;
            margin-bottom: 15px;
        }
    }

    .ms-content-block__text {
        @include text-font-style();

        p {
            margin-bottom: 0;
        }
    }
}

.wine-club-signup {

    //-----------------------------------------//
    //      WineClub Signup - Steps Titles     //
    //-----------------------------------------//
    &-club-title,
    &-wine-preference-title,
    &-variant-title,
    &-membership-title,
    &-shipping-title,
    &-terms-title,
    &-questions .heading {
        @include heading-font-style();
        margin-top: 0;
        margin-bottom: $wineclub-space-s;
        text-align: center;
    }

    &-variant-title {
        display: none;
    }

    //---------------------------------//
    //    WineClub Signup - Benefits   //
    //---------------------------------//

    &-benefits-button {
        max-width: $wineclub-page-max-width;
        margin: 0 auto $wineclub-page-space-s;
        background-color: transparent;
        text-align: center;

        @include wc-breakpoint(lg) {
            margin: 0 auto ($wineclub-page-space-s + 10px);
        }

        @include wc-breakpoint(xl) {
            padding: 0 $wineclub-page-space-l;
        }

        .overflow-container-btn {
            @include subheading-font-style();
            @include vfi-focus();
            position: relative;
            background-color: transparent;
            border: none;

            &::after {
                content: '';
                border-width: 0 10px 12px 10px;
                border-color: transparent transparent $wineclub-text-color transparent;
                border-style: solid;
                height: 0;
                width: 0;
                margin: 5px auto -1px;
                display: block;
                transform: scaleY(-1);
                transition: ease transform 0.3s;
            }
        }

        &.overflow-container-opened .overflow-container-btn::after {
            transform: rotate(0deg);
        }

        &.overflow-container-closed .ms-content-block__details {
            max-height: 0;
            opacity: 0;
            padding: 0 $wineclub-page-space-s;
            visibility: hidden;

            @include wc-breakpoint(md) {
                padding: 0 ($wineclub-page-space-s + 10px);

            }
        }

        &.overflow-container-opened .ms-content-block__details {
            max-height: 800px;
            opacity: 1;
            visibility: visible;
        }
    }

    &-benefits-content {
        min-height: 0;


        .ms-content-block {
            &__title {
                margin-bottom: $wineclub-space-s;
            }

            &__details {
                background-color: $wineclub-color-bg-light;
                padding: 30px;
                transition: opacity 0.6s ease, max-height 0.6s ease, visibility 0.6s ease, padding 1s ease;
                overflow: hidden;

                @include wc-breakpoint(md) {
                    padding: 40px;
                }
            }

            &__text {
                margin: 0 auto;

                @include wc-breakpoint(md) {
                    max-width: 525px;
                }

                @include wc-breakpoint(lg) {
                    max-width: none;
                }

                ul {
                    column-count: 1;
                    column-width: 300px;
                    column-gap: 30px;
                    text-align: left;
                    color: $wineclub-text-color;
                    margin-bottom: 0;
                    padding-left: 1.5rem;

                    @include wc-breakpoint(lg) {
                        column-count: 2;
                    }
                }

                li {
                    @include text-font-style();
                    margin-bottom: 5px;
                }

                ul,
                li {
                    list-style: disc;
                }
            }
        }
    }

    //---------------------------------//
    //      WineClub Signup - Club     //
    //---------------------------------//
    &-club {
        @include add-page-space;
        max-width: none !important;

        &-items {
            margin-bottom: $wineclub-space-m;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            gap: 30px;
            align-items: center;
            flex-direction: column;


            @include wc-breakpoint(lg) {
                margin-bottom: $wineclub-space-l;
                align-items: initial;
                justify-content: center;
                flex-direction: row;
            }


            &-more {
                .wine-club-signup-club-item-pricing-range {
                    @include wc-breakpoint(lg) {
                        font-size: 12px;
                        line-height: 1.33;
                    }

                    @include wc-breakpoint(xl) {
                        font-size: 14px;
                        line-height: 1.33;
                    }
                }

                .wine-club-signup-club-item-pricing-cost-notif {
                    @include wc-breakpoint(lg) {
                        font-size: 10px;
                        line-height: 1.6;
                    }

                    @include wc-breakpoint(xl) {
                        font-size: 12px;
                        line-height: 1.33;
                    }
                }

                .wine-club-signup-club-item-description {
                    @include wc-breakpoint(lg) {
                        font-size: 12px;
                        line-height: 1.33;
                    }

                    @include wc-breakpoint(xl) {
                        font-size: 14px;
                        line-height: 1.43;
                    }
                }
            }

            &-less {
                .wine-club-signup-club-item-pricing-range {
                    @include wc-breakpoint(lg) {
                        font-size: 14px;
                        line-height: 1.33;
                    }
                }

                .wine-club-signup-club-item-pricing-cost-notif {
                    @include wc-breakpoint(lg) {
                        font-size: 12px;
                        line-height: 1.33;
                    }
                }

                .wine-club-signup-club-item-description {
                    @include wc-breakpoint(lg) {
                        font-size: 14px;
                        line-height: 1.43;
                    }

                }
            }

        }

        &-item {
            position: relative;
            max-width: $wineclub-item-max-width;
            border: 1px solid $wineclub-item-color;
            transition: box-shadow ease 0.4s;
            width: 100%;

            @include wc-breakpoint(lg) {
                max-width: ($wineclub-item-max-width - 20px);
            }

            &.active {
                box-shadow: 0 0 0 1px transparentize($color: $wineclub-item-color, $amount: 0);
            }
        }

        &-item-image {
            display: none;

            @include wc-breakpoint(lg) {
                display: block;
                height: 290px;
            }

            img {
                min-width: 100%;
                width: 100%;
                height: 100%;
                min-height: 250px;
                max-height: 290px;
                object-fit: cover;
                object-position: center center;
                padding: 0 0 15px;
            }
        }


        &-item-title {
            padding-left: $wineclub-space-s;
            padding-right: $wineclub-space-s;
            padding-top: 20px;
            font-family: $wineclub-font-family-heading;
            margin-bottom: 5px;
            font-size: 22px;
            line-height: 1.45;
            color: $wineclub-text-color;
            text-align: center;

            @include wc-breakpoint(md) {
                font-size: 24px;
                line-height: 1.33;
            }

            @include wc-breakpoint(lg) {
                padding-top: 0;
            }

            @include wc-breakpoint(xl) {
                font-size: 26px;
                line-height: 1.54;
            }
        }

        &-item-subtitle {
            padding-left: $wineclub-space-s;
            padding-right: $wineclub-space-s;
            margin-bottom: 15px;
            padding-bottom: 15px;
            color: $wineclub-text-color;
            text-align: center;
            font-size: 10px;
            line-height: 1.6;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            border-bottom: 1px solid $wineclub-item-color-border;

            @include wc-breakpoint(md) {
                font-size: 11px;
                line-height: 1.45;
            }

            @include wc-breakpoint(xl) {
                font-size: 12px;
                line-height: 1.33;
            }
        }

        &-item-content-body {
            p {
                margin-bottom: 0;
            }
        }

        &-item-pricing-range {
            font-family: $wineclub-font-family-body;
            font-size: 13px;
            line-height: 1.23;
            color: $wineclub-item-color;
            text-align: center;
            padding-left: $wineclub-space-s;
            padding-right: $wineclub-space-s;

            @include wc-breakpoint(md) {
                font-size: 14px;
                line-height: 1.71;
            }
        }

        &-item-pricing-cost-notif {
            display: none;
            font-family: $wineclub-font-family-body;
            font-size: 11px;
            line-height: 1.45;
            color: $wineclub-item-color;
            text-align: center;
            margin-top: 5px;
            padding-left: $wineclub-space-s;
            padding-right: $wineclub-space-s;

            @include wc-breakpoint(md) {
                font-size: 12px;
                line-height: 1.33;
            }
        }

        &-item-description {
            font-family: $wineclub-font-family-body;
            color: $wineclub-item-color;
            font-size: 13px;
            line-height: 1.54;
            text-align: center;
            margin-top: 20px;
            margin-bottom: ($wineclub-item-button-size + 20px);
            padding-left: $wineclub-space-s;
            padding-right: $wineclub-space-s;

            @include wc-breakpoint(md) {
                font-size: 14px;
                line-height: 1.71;
            }

            @include wc-breakpoint(lg) {
                line-height: 1.43;
            }
        }


        &-item-additional-benefits {
            border-top: 1px solid $wineclub-item-color-border;
            border-bottom: 1px solid $wineclub-item-color-border;
            margin-bottom: 20px;

            &.empty {
                display: none;
            }

            &-button {
                @include vfi-focus();
                position: relative;
                width: 100%;
                padding: 15px;
                background-color: transparent;
                border: none;
                outline: none;
                color: $wineclub-text-color;
                font-family: $wineclub-font-family-link;
                font-size: 14px;
                line-height: 1.71;
                letter-spacing: 0.1em;
                text-transform: uppercase;

                @include wc-breakpoint(xl) {
                    font-size: 16px;
                    line-height: 1.5;
                }

                &.closed::after,
                &.open::after {
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translateY(-50%);
                    font-size: 24px;
                    font-weight: bold;

                }

                &.closed::after {
                    content: '+';
                }

                &.open::after {
                    content: '-';
                }
            }

            &-content {
                padding-left: $wineclub-space-s;
                padding-right: $wineclub-space-s;

                &.closed {
                    display: none;
                }

                &.open {
                    margin-bottom: 20px;
                    display: block;
                }
            }
        }

        &-item-button {
            text-align: center;

            button {
                @include btn(secondary);
                @extend %btn-selection;
                position: absolute;
                bottom: 0;
                border-width: 1px 0 0 0;
                width: 100% !important;
                height: $wineclub-item-button-size;

                &:disabled {
                    @include btn(primary);
                }
            }
        }
    }

    //----------------------------------------------------------//
    //  WineClub Signup - Wine Preference and membership block  //
    //----------------------------------------------------------//
    &-wine-preference,
    &-membership {
        @include add-page-space;
        @extend %fadeInDown;
        margin-bottom: $wineclub-space-m;

        @include wc-breakpoint(lg) {
            margin-bottom: $wineclub-space-l;
        }

        &-buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;

            button {
                @include btn(secondary);
                @extend %btn-selection;

                &:disabled {
                    @include btn(primary);
                }
            }
        }
    }

    //-------------------------------------//
    //    WineClub Signup - Wine Variant   //
    //-------------------------------------//
    &-variant {
        @include add-page-space;
        @extend %fadeInDown;
        margin-bottom: $wineclub-space-m;

        @include wc-breakpoint(lg) {
            margin-bottom: $wineclub-space-l;
            padding-bottom: $wineclub-space-l;
            border-bottom: 1px solid $wineclub-main-border-color;
        }

        &.empty {
            display: none;
        }

        &-expand-button {
            @include vfi-focus();
            width: 95%;
            padding: 10px;
            margin: 0 auto 20px;
            background-color: transparent;
            border: none;
            outline: none;
            color: $wineclub-text-color;
            border-top: 1px solid $wineclub-item-color-border;
            border-bottom: 1px solid $wineclub-item-color-border;
            display: flex;
            justify-content: center;
            align-items: center;

            &.closed::after,
            &.open::after {
                margin-left: 15px;
                display: inline-block;
                font-size: 24px;
                font-weight: bold;

            }

            &.closed::after {
                content: '+';
            }

            &.open::after {
                content: '-';
            }
        }

        &-info-container {
            @include wc-breakpoint(lg) {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: center;
                column-gap: 40px;
            }
        }

        &-image {
            display: none;

            @include wc-breakpoint(lg) {
                display: block;
            }

            img {
                min-width: 100%;
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        &-image,
        &-info {
            @include wc-breakpoint(lg) {
                width: 47%;
            }
        }

        &-info {

            &-title,
            &-subtitle {
                display: none;
            }

            &-description {
                text-align: center;
                max-width: 525px;
                margin: 0 auto;

                @include wc-breakpoint(lg) {
                    text-align: left;
                    max-width: none;
                    margin: 0;
                }

                h3 {
                    color: $wineclub-text-color;
                    font-family: $wineclub-font-family-heading;
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 0;

                    @include wc-breakpoint(md) {
                        font-size: 28px;
                        line-height: 40px;
                    }

                    @include wc-breakpoint(lg) {
                        font-size: 32px;
                        line-height: 48px;
                    }

                    @include wc-breakpoint(xl) {
                        font-size: 36px;
                    }
                }

                p {
                    color: $wineclub-text-color;
                    font-family: $wineclub-font-family-body;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 24px;
                    margin-bottom: 15px;

                    @include wc-breakpoint(md) {
                        font-size: 16px;
                    }

                    @include wc-breakpoint(xl) {
                        font-size: 18px;
                    }
                }

                ul,
                li {
                    list-style: disc;
                    text-align: left;
                }

                ul {
                    margin-bottom: 0;
                    padding-left: 1.5rem;

                    li {
                        @include text-font-style();

                        a {
                            @include wineclub-link;
                        }
                    }
                }
            }
        }
    }

    //-------------------------------------//
    //     WineClub Signup - Shipping      //
    //-------------------------------------//
    &-shipping {
        @include add-page-space;
        @extend %fadeInDown;
        background-color: $wineclub-color-bg-light;
        text-align: center;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 30px;

        @include wc-breakpoint(lg) {
            padding-top: 40px;
            padding-bottom: 40px;
            margin-bottom: 40px;
        }

        label {
            margin-bottom: 0;
        }

        select {
            margin-bottom: 20px;
            width: 100%;
            border: 1px solid transparentize($color: $wineclub-text-color, $amount: 0.8);
            background-color: transparent;
            color: $wineclub-text-color;
            padding: 10px;
            font-size: 14px;
            text-transform: uppercase;

            @include wc-breakpoint(btn) {
                width: auto;
                min-width: 300px;
            }

            @include wc-breakpoint(xl) {
                font-size: 15px;
            }
        }

        &-pickup-address {
            @include text-font-style();
            margin-bottom: 5px;
        }

        &-description {
            @include text-font-style();
        }

        .shipping-popup {
            margin-top: 5px;

            @include wc-breakpoint(lg) {
                margin-top: 10px;
            }

            button {
                @include vfi-focus();
                @include wineclub-link;
                padding: 5px;
                background-color: transparent;
                border: none;
                font-size: 14px;
                line-height: 24px;

                @include wc-breakpoint(md) {
                    font-size: 15px;
                }

                @include wc-breakpoint(lg) {
                    font-size: 16px;
                }
            }
        }
    }

    //-------------------------------------//
    //       WineClub Signup - Terms       //
    //-------------------------------------//
    &-terms-block {
        margin-bottom: 30px;

        @include wc-breakpoint(lg) {
            margin-bottom: 40px;
        }
    }

    &-terms {
        @include add-page-space;

        .page-tos {
            max-width: 900px;
            margin: 0 auto;
            &-text {
                @include text-font-style;

                p {
                    margin-bottom: 15px;
                }

                a {
                    @include wineclub-link;
                    color: $wineclub-text-color !important;
                }
            }

            &-checkbox {
                padding-top: 5px;
                display: flex;
                align-items: flex-start;
                margin-bottom: 30px;

                @include wc-breakpoint(lg) {
                    margin-bottom: 40px;
                }

                &-checkbox {
                    position: relative;
                    top: 1px;

                    @include wc-breakpoint(md) {
                        position: static;
                    }
                }

                &-text {
                    margin-left: 15px;
                    margin-bottom: 0;
                    font-family: $wineclub-font-family-link;
                    color: $wineclub-text-color;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 1.2px;
                    text-transform: uppercase;

                    @include wc-breakpoint(md) {
                        font-size: 13px;
                    }

                    @include wc-breakpoint(xl) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    //-------------------------------------//
    //      WineClub Signup - Signup       //
    //-------------------------------------//
    &-signup {
        @include add-page-space;
        text-align: center;
        margin-bottom: 5px;

        &-button {
            @include btn(primary);
            min-width: 150px;
            width: 100%;
            transition: ease-in-out 0.3s opacity;

            @include wc-breakpoint(btn) {
                width: auto;
            }

            @include wc-breakpoint(lg) {
                min-width: 200px;
            }

            &:disabled {
                opacity: 0.3;
            }
        }
    }

    &-signup-message {
        @include add-page-space;
        text-align: center;
        font-family: $wineclub-font-family-body;
        font-size: 13px;
        line-height: 24px;
        color: $wineclub-text-color;

        p {
            margin-bottom: 0;
        }

        @include wc-breakpoint(xl) {
            font-size: 14px;
        }

    }

    //-------------------------------------//
    //     WineClub Signup - Questions     //
    //-------------------------------------//

    &-questions {
        @include add-page-space;
        margin-bottom: 30px;

        @include wc-breakpoint(lg) {
            margin-bottom: 40px;
        }

        .heading {
            margin-top: 0;
            width: 100%;
        }

        .ms-text-block * {
            @include text-font-style;
        }

        .ms-text-block *:not(a) {
            margin-bottom: 0;
            text-align: center;
        }

        .ms-text-block a {
            @include wineclub-link;
            color: $wineclub-text-color !important;
        }
    }

    //-------------------------------------//
    //  WineClub Signup - Shipping Modal   //
    //-------------------------------------//
    &-shipping-modal {
        .msc-modal__dialog {
            width: 80%;

            @include wc-breakpoint(md) {
                width: auto;
            }
        }

        .msc-modal__content .msc-modal__header .msc-modal__close-button {
            transition: color ease 0.3s;

            &:hover {
                color: $wineclub-button-color-primary;
                opacity: 1;
            }

        }

        .wine-club-signup-shipping-modal-title {
            font-family: $wineclub-font-family-heading;
            text-align: center;
            font-size: $wineclub-font-size-modal-heading;
            line-height: ($wineclub-font-size-modal-heading + 10px);
            color: $wineclub-text-color;
            margin-bottom: 0;

            @include wc-breakpoint(md) {
                font-size: ($wineclub-font-size-modal-heading + 4px);
                line-height: ($wineclub-font-size-modal-heading + 18px);
            }

            @include wc-breakpoint(lg) {
                font-size: ($wineclub-font-size-modal-heading + 10px);
                line-height: ($wineclub-font-size-modal-heading + 26px);
            }

        }


        &-content {
            padding: 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            gap: 30px;

            @include wc-breakpoint(md) {
                padding: 30px 60px 60px;
            }
        }

        &-map {
            width: 100%;

            .shipping-map {
                max-width: 80%;
                margin: 0 auto;

                @media (min-width: 576px) {
                    max-width: 60%;
                }

                @include wc-breakpoint(md) {
                    max-width: 100%;
                }
            }

            @include wc-breakpoint(xl) {
                max-width: 50%;
                width: auto;
            }

            .shipping-map-svg {
                height: 100%;

                @include wc-breakpoint(xl) {
                    height: auto;
                }
            }

            .shipping-map-legend-item {
                @include text-font-style;
            }
        }

        &-description {
            @include text-font-style;
            width: 100%;

            @include wc-breakpoint(xl) {
                max-width: 43%;
                width: auto;
            }

            p {
                margin-bottom: 15px;
            }

        }

        ul {
            column-count: 2;
            column-width: 70px;
            column-gap: 5px;
            padding-left: 0;
            margin-bottom: 0;
        }
    }
}