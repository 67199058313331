.shop.buyboxlite {
  overflow: hidden;
  .col-12 {
    padding: 0;
  }

  #renderPage header {
    margin: 0;
  }

  #main {
    width: 100%;
    margin: 0;
    padding: 1.5rem;

    .ms-buybox {
      max-width: 961px;
      padding: 0;
      margin: 0 -15px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      &__media-gallery {
        @extend %col-properties;
        max-width: 28%;
        flex: 0 0 28%;
        padding: 0;
        margin: 0;
        border: 0;

        .ms-media-gallery {
          @extend %col-properties;
          max-width: 100%;
          padding: 0;
          margin: 0;
          border: 0;
        }
        .ms-media-gallery.overflow-hidden {
          overflow: inherit !important;
        }
      }
      &__content {
        max-width: 72%;
        flex: 0 0 72%;
        margin: 0;
        padding: 0 2rem 0 1rem;
      }
      &__product-title {
        width: 100%;
        color: $color-text-black;
      }
    }
    .product-attribute {
      &-apellation-value {
        text-align: left;
        margin: 0;
        font-family: $font-family-link;
        font-size: 14px;
        color: $color-text-black;
        line-height: 1.5;
      }
      &-nv {
        display: block;
      }
    }

    .ms-media-gallery img {
      max-width: 150px;
      max-height: 387px;
      height: auto;
    }
    .ms-media-gallery .msc-carousel__inner .msc-carousel__item img {
      margin: 0 auto;
      display: block;
    }
    .product-link {
      margin-top: 1rem;
      display: block;
      order: 1;
      margin-right: auto;
      margin-left: auto;
    }

    .product-link-cta {
      display: block;
    }

    .media-gallery-carousel {
      max-width: 100%;
    }

    .product-title-heading {
      width: 100%;
    }

    .product-attribute-nv {
      padding-top: 0.5rem;
      display: block;
      &-value {
        display: block;
      }
    }

    .sku-selector-container {
      padding: 0;
      margin: 3.5rem 0 1rem;
      border-bottom: 1px solid $color-silver;
      .msc-add-to-cart {
        position: static !important;
      }
      .sku-selector-add-to-cart {
        .msc-add-to-cart::before {
          display: none;
        }
        .msc-alert {
          margin: 0 0 1rem 0;
          left: 0;
          top: -3rem;
          padding: 0.25rem 0.75rem;
        }
      }
      .table {
        table-layout: auto;
        @include media-breakpoint-up(lg) {
          table-layout: fixed;
        }
        width: 100%;
      }

      .table-responsive .table {
        background-color: transparent;
        display: table;

        th {
          margin: 0;
          text-align: left;
        }

        tbody {
          display: table-header-group;
        }

        thead {
          display: table-header-group;
        }
        .sku-selector-add-to-cart {
          left: 0;
        }
        tr {
          display: table-row;
        }
      }

      .sku-selector-product-title {
        width: 180px;
      }

      .sku-selector-product-quantity {
        height: auto;
        justify-content: flex-start;
      }

      .product-price input {
        width: 50px;
      }

      .product-price.keyed-in {
        margin-top: 0;
      }

      .quantity,
      .quantity-input {
        background-color: $color-input-background;
        width: 50px;
      }

      .product-add-to-cart {
        padding: 0;

        .call-to-action__glyph {
          display: none;
        }

        p {
          margin: -0.5rem;
          font-size: $font-size-body-xxs - 1px;
        }

        .alert {
          top: -1.5rem;
          width: 100%;
          padding: 0.25rem 0.75rem 0.1rem;
        }
      }
    }

    .message-alert {
      padding: 0;
      margin: 0;
    }

    .help-text-container {
      padding: 0;
      font-size: $font-size-body-s;
      color: $color-text-black;
      margin-top: 1rem;
    }
  }

  #renderPage footer {
    margin: 0;
  }
}

.shop.modal-open {
  .msc-modal {
    &__dialog {
      width: 100%;
      height: 100%;
      background: transparent;
      border-radius: 0;

      @include media-breakpoint-up(md) {
        width: initial;
      }
      @include media-breakpoint-up(lg) {
        height: initial;
      }
    }
    &__content {
      border-radius: 0;
      border: 1px solid transparent;
      .product-iframe {
        width: 100%;
        @include media-breakpoint-up(md) {
          width: 759px;
        }
      }
    }
  }
}
