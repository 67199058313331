$payment-instrument-min-height: 35px;
$payment-instrument-margin: -0.5rem;
$payment-instrument-loading-size: 100%;
$msv-checkout-payment-circular-size: 25px !important;
.ms-checkout-payment-instrument,
.checkout-payment-instrument {
  .msc-waiting {
    display: block;
  }
  position: relative;
  min-height: $payment-instrument-min-height;
  margin-left: $payment-instrument-margin;
  &__loading-background {
    display: flex;
    padding: 0.5rem;
    .waiting {
      display: block;
      margin: auto;
    }
    .msc-waiting-circular {
      width: $msv-checkout-payment-circular-size;
      height: $msv-checkout-payment-circular-size;
    }
  }

  &__iframe {
    border: 0;
  }

  &__show {
    padding: 0 0.5rem;
    .ms-checkout-payment-instrument__card-title {
      @extend %text-shop-heading-secondary;
      font-size: $font-size-body-m;
      font-weight: bold;
    }
    p {
      &:not(:first-child) {
        margin: 0;
      }
    }
  }
  &__add {
    &.is-fetching {
      opacity: 0;
    }
  }
  &__error {
    &-title {
      display: none;
    }
    &-message {
      @extend %error-alert;
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
      padding: 0.5rem 0.75rem;
    }
  }
  &__card-type-id {
    display: none;
  }
}

// Paypal ready styling
.ms-checkout-payment-instrument-paypal .ms-checkout-payment-instrument {
  &__card-title {
    margin-bottom: 0;
    font-weight: bold;
    text-transform: uppercase;
  }
  &__card-type-id {
    display: block;
  }
  &__card-title,
  &__card-name,
  &__card-type-id,
  &__card-expiration {
    margin-bottom: 0;
    font-size: $font-size-body-m;
    color: $color-link-secondary;
  }
}