.event-list {
  // display: flex;
  flex-wrap: wrap;
  max-width: 960px;
  margin: 2rem;
  margin-top: 0;
  text-align: center;

  @include media-breakpoint-up(sm) {
    margin: 2rem auto;
    margin-top: 0;
  }

  // @include media-breakpoint-up(lg) {
  //   margin: 4rem auto;
  // }

  &-empty {
    font-size: $font-size-body-l;
    margin: 2rem;
    max-width: 960px;
    text-align: center;
    @include media-breakpoint-up(sm) {
      margin: 2rem auto;
    }
    @include media-breakpoint-up(lg) {
      margin: 4rem auto;
    }
  }

  &-event {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 100%;
    position: relative;
    padding: 1rem 0;
    background-color: $color-white;
    font-size: $font-size-body-m;
    line-height: 1.3;

    &:not(:first-child) {
      margin-top: 1rem;
    }

    @include media-breakpoint-up(md) {
      flex: 1 1 calc(96% / 2);
      // max-width: calc(96% / 2);
      margin: 1%;

      &:not(:first-child) {
        margin-top: 1%;
      }
    }
    hr {
      width: 100%;
      border: 0;
      margin: 20px 0 26px;
      height: 1px;
      background-color: $color-darkgrey;
      color: $color-darkgrey;
    }

    > * {
      display: block;
      // width: calc(100% - 125px);
      margin: auto;
    }
  }

  &-date {
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 125px;
    // height: 100%;
    margin: 0;
    background-color: $color-white;
    font-family: $font-family-link;
    font-size: $font-size-link-m;
    text-transform: uppercase;
  }

  &-range {
    height: 100%;
  }

  &-single,
  &-range > .event-list-start {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &-range {
    & > .event-list-date-range {
      display: inline-flex;
      .event-list-separator {
        display: inline-flex;
        align-items: flex-start;
        padding: 0 0.5rem;
        &::after {
          content: "-";
          font-size: 42px;
        }
      }
      .event-list-start,
      .event-list-end {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }

  &-month-M {
    @extend h3;
    color: $color-darkgrey !important;
    font-weight: 400;
    font-size: 21px;
    margin-bottom: 0 !important;
    display: block;
  }

  &-day {
    @extend h1;
    color: $color-text-black !important;
    font-size: 60px;
    font-weight: 400;
  }

  &-category,
  &-location,
  &-time {
    @extend h4;
    margin-bottom: 0 !important;
    color: $color-text-black;
    display: none;
  }

  &-name {
    @extend h2;
    color: $color-text-black !important;
    font-size: $font-size-body-xxxxl !important;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: inherit !important;
      text-decoration: underline;
    }
  }

  &-sold-out {
    margin-top: 0.5rem !important;
    color: $color-link-primary;
    font-weight: bold;
    text-transform: uppercase;
  }

  &-image,
  &-month-S,
  &-year-S,
  &-year-L,
  &-intro,
  &-price,
  &-separator,
  &-end,
  &-button {
    display: none;
  }
}
