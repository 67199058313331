$carousel-control-width: 48px;
$carousel-control-opacity: 1;
$carousel-control-hover-opacity: 0.9;
$carousel-control-transition: opacity 0.15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 12px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-transition: opacity 0.6s ease;
$carousel-indicator-margin-left-right: 6px;

$carousel-caption-width: 70%;

$carousel-control-icon-width: 20px;
$carousel-control-prev-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$color-white}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$carousel-control-next-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$color-white}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"),
  "#",
  "%23"
);
$carousel-transition-duration: 0.6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

$carousel-transition-base: all 0.2s ease-in-out;
$carousel-transition-fade: opacity 0.15s linear;
$carousel-transition-collapse: height 0.35s ease;
$carousel-enable-transitions: true;
$carousel-enable-prefers-reduced-motion-media-query: true;

@mixin carousel-transition($transition...) {
  @if $carousel-enable-transitions {
    @if length($transition) == 0 {
      transition: $carousel-transition-base;
    } @else {
      transition: $transition;
    }
  }

  @if $carousel-enable-prefers-reduced-motion-media-query {
    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.msc-carousel {
  position: relative;
  align-items: center;
  display: flex;

  .pointer-event {
    touch-action: pan-y;
  }

  &__dismiss {
    background: $color-black;
    border-color: $color-black;
    color: $color-white;
    position: absolute;
    top: 0;
    right: 0;
    &:before {
      @include msv-icon();
      content: $msv-Cancel;
      font-size: 20px;
      position: unset;
      text-align: center;
      vertical-align: text-bottom;
      visibility: visible;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    @include clearfix();
  }

  &__item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    @include carousel-transition($carousel-transition);
    @media screen and (max-width: $breakpoint-s) {
      height: 100%;
    }
  }

  &__item {
    &.active,
    &__next,
    &__prev {
      display: block;
    }
  }

  &.carousel-fade {
    .msc-carousel__item {
      opacity: 0;
      transition-property: opacity;
      transform: none;
    }

    .msc-carousel__item.active,
    .msc-carousel__item__next.msc-carousel__item__left,
    .msc-carousel__item__prev.msc-carousel__item__right {
      z-index: 1;
      opacity: 1;
    }

    .active.msc-carousel__item__left,
    .active.msc-carousel__item__right {
      z-index: 0;
      opacity: 0;
      @include carousel-transition(0s $carousel-transition-duration opacity);
    }
  }

  &__control__prev,
  &__control__next {
    width: $carousel-control-width;
    height: $carousel-control-width;
    top: calc(50% - 24px);
    transition: none;
    position: absolute;
    bottom: 0;
    z-index: 16;
    display: none;
    align-items: center;
    justify-content: center;
    color: $color-white;
    text-align: center;
    opacity: $carousel-control-opacity;
    @include carousel-transition($carousel-control-transition);

    // Hover/focus state
    @include hover-focus {
      color: $color-black;
      text-decoration: none;
      outline: 1px dashed $color-black;
      opacity: 0.9;
      &::before {
        outline: 1px dashed $color-white;
      }
      &__icon {
        outline: 0;
        &::before {
          outline: 0;
        }
      }
    }

    &::before {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      outline-offset: 1px;
    }
  }

  &__control__prev {
    left: 0;
    justify-content: flex-start;
    margin-left: 2px;

    &__icon {
      margin-left: 15px;
    }
  }

  &__control__next {
    justify-content: flex-end;
    margin-right: 2px;
    right: 0;

    &__icon {
      margin-right: 15px;
    }
  }

  &__caption {
    position: absolute;
    right: (100% - $carousel-caption-width) / 2;
    bottom: 20px;
    left: (100% - $carousel-caption-width) / 2;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $color-white;
    text-align: center;
  }

  &__indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0; // override <ol> default
    // Use the .carousel-control's width as margin so we don't overlay those
    margin-right: $carousel-indicator-margin-left-right;
    margin-left: $carousel-indicator-margin-left-right;
    list-style: none;
    display: none;
    .msc-tooltip {
      display: none !important;
    }

    li {
      @include carousel-transition($carousel-indicator-transition);
      @include vfi-psuedo(after, 3px);
      height: 3px;
      opacity: 0.5;
      background-color: $color-white;
      box-sizing: content-box;
      flex: 0 1 auto;
      width: $carousel-indicator-width;
      margin-right: $carousel-indicator-spacer;
      margin-left: $carousel-indicator-spacer;
      text-indent: -999px;
      cursor: pointer;
      background-clip: padding-box;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      &:focus {
        outline: 0;
      }
    }

    .active {
      background-color: $color-white;
      opacity: 1;
    }
  }

  &__control__prev__icon,
  &__control__next__icon {
    display: inline-block;
    width: $carousel-control-icon-width;
    height: $carousel-control-icon-width;
    background: no-repeat 50% / 100% 100%;
  }

  &__control__prev__icon {
    background-image: $carousel-control-prev-icon-bg;
  }

  &__control__next__icon {
    background-image: $carousel-control-next-icon-bg;
  }
}

.msc-carousel__item__next:not(.msc-carousel__item__left),
.active.msc-carousel__item__right {
  transform: translateX(100%);
}

.msc-carousel__item__prev:not(.msc-carousel__item__right),
.active.msc-carousel__item__left {
  transform: translateX(-100%);
}

@media screen and (max-width: $msv-breakpoint-m) {
  .msc-carousel {
    &__inner {
      color: $color-white;
    }
  }
}

@media screen and (max-width: $msv-breakpoint-l) {
  .msc-carousel {
    &__control {
      &__prev,
      &__next {
        display: none;
      }
    }
  }
}
