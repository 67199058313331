%shop-container {
  max-width: $max-shop-page-width;
  padding: 1rem;
  background-color: $color-background-primary;

  @include media-breakpoint-up(sm) {
    width: $page-width;
    padding: 1.5rem 2rem;
  }
}

.shop {
  min-width: calc(347px + 2rem);
  // background-image: $asset-background-shop;
  // background-size: cover;
  // background-attachment: scroll;
  // background-repeat: no-repeat;
  background-color: $color-background-shop;
  color: $color-text-black;
  font-family: $font-family-link;
  font-size: $font-size-link-l;

  header {
    margin-bottom: 1rem;
    @include breakpoint(lg) {
      margin-bottom: 0.5rem;
    }
    .ms-header__container {
      max-width: $max-shop-page-width;
    }
  }

  #main {
    @extend %shop-container;
    margin: 2rem 0 0;

    @include media-breakpoint-up(sm) {
      margin: 2rem auto;
    }

    @include media-breakpoint-up(lg) {
      margin: 4rem auto 2rem;
    }
  }

  #subfooter {
    @extend %shop-container;
    margin-top: -1px;

    @include media-breakpoint-up(sm) {
      margin: -3rem auto 2rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: $color-text-black;
    font-family: $font-family-heading-shop;
  }

  .hero {
    margin-bottom: 1rem;

    > div {
      padding: 0.4rem;
    }

    .theme-dark {
      background-color: $color-background-tertiary;

      .hero-heading {
        color: $color-heading-secondary;
      }
    }

    .hero-heading {
      margin-bottom: 0.2rem;
      font-size: $font-size-heading-shop-xs;
      text-align: center;
      color: $color-heading-primary;
    }

    p {
      margin: 0;
      color: $color-text-tertiary;
      font-size: $font-size-body-s;
      text-align: center;
    }

    a {
      color: $color-link-tertiary;

      &:hover,
      &:focus,
      &:active {
        color: $color-link-tertiary-hover;
      }
    }
  }
}

.sort_and_filter_desktop_view {
  .col-lg-3 {
    display: none;
  }

  .col-lg-9 {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
}

.sort_and_filter_mobile_view {
  .sort-and-filter-container {
    display: none;
  }
}

.products-by-category {
  .category-title {
    font-size: $font-size-heading-shop-l;
  }

  .category-description {
    margin-bottom: 1rem;
    color: $color-text-black;
    font-size: $font-size-body-s;
    // font-style: italic;
    line-height: 1.4;
  }

  .product-placement__item {
    position: relative;
    z-index: 1;
    display: inline-block;
    max-width: 317px;
    height: 330px;
    padding: 0;
    margin-right: 2px;
    border-top: solid 1px $color-border-primary;
    vertical-align: top;
    line-height: 18px;

    &:focus {
      outline: none;
    }

    a,
    a:hover,
    a:visited {
      text-decoration: none;
      color: #3e280d;
      padding: 0;
    }

    .product-details {
      width: 317px;

      .fa-chevron-right {
        color: $color-link-primary;
        float: right;
        margin: 55px 10px 0px 0px;
      }

      .product-details-header {
        background: $color-background-secondary;
        padding: 30px 30px 30px 90px;
        height: 130px;
      }

      .appellation {
        color: $color-text-primary;
        font-size: $font-size-body-xs;
        font-style: italic;
        text-transform: uppercase;
      }

      .description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 20px 15px 0 90px;
        color: $color-text-primary;
        font-size: $font-size-body-s;
        line-height: 1.5;
        -webkit-line-clamp: 4;
      }

      .acclaim-list {
        padding: 8px 0 0 90px;
        max-width: 240px;
        min-height: 85px;
      }

      .acclaim-entry {
        float: left;
        padding-top: 10px;
      }
    }

    .product-placement__item-image {
      display: block;
      margin: auto;
      position: absolute;
      bottom: 52px;
      left: 18px;
      max-width: 60px;
      max-height: 260px;
    }

    .product-placement__item-title {
      top: 0;
      max-height: 60px;
      overflow: hidden;
      color: $color-link-primary;
      font-family: $font-family-heading;
      font-size: $font-size-heading-shop-xs;
      text-transform: uppercase;

      &:hover,
      &:focus,
      &:active {
        color: $color-link-primary-hover;
      }
    }

    .product-lightbox {
      display: none;
      position: absolute;
      bottom: 50px;
      right: 15px;

      @include media-breakpoint-up(sm) {
        display: block;
      }

      .launch-lightbox-button {
        width: 55px;
        text-align: center;
        border: 1px solid $color-border-tertiary;
        padding: 3px 0 2px;
        background-color: $color-background-secondary;
        color: $color-link-primary;
        font-size: $font-size-link-s;

        &:hover,
        &:focus,
        &:active {
          box-shadow: none;
          border-color: $color-border-tertiary;
          background-color: $color-background-secondary;
          color: $color-link-primary-hover;
        }
      }
    }
  }
}
