.account {
  .container-with-header-header {
    @extend %text-shop-heading-container;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .container-with-header-title {
      @extend %text-shop-heading-primary;
    }

    .container-with-header-title,
    .container-with-header-text p {
      margin-bottom: 0;
    }
  }

  .container-with-header-slots {
    display: flex;
    flex-wrap: wrap;

    > div:nth-child(2) {
      flex: 0 0 100%;

      @include media-breakpoint-up(lg) {
        flex: 1 1 60%;
      }
    }

    > div:nth-child(3) {
      flex: 0 0 100%;

      @include media-breakpoint-up(lg) {
        flex: 1 1 39.5%;
        margin-left: 0.5%;
      }

      .content-block-with-title {
        padding: 2rem 2rem 0;
      }

      .content-block-with-title-text p:first-child {
        font-size: $font-size-body-xxl;
      }
    }
  }

  .ms-account {
    &-welcome-tile {
      @extend %col-properties;
      display: flex;
      flex-wrap: wrap;
      padding: 0 2rem;
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 1.5rem;
      }
      &__heading {
        @extend %text-shop-heading-secondary;
        margin-bottom: 1.5rem;
        font-weight: normal;
        text-transform: none;
        line-height: 1;
      }
    }
  }

  .account-management-address {
    padding: 2rem;

    > div:not(:first-child) {
      margin-top: 2rem;
    }
  }

  .address-list__add-heading {
    @extend %text-shop-heading-secondary;
  }

  .address-list__primary-heading,
  .address-list__other-heading {
    @extend %text-shop-heading-secondary;
    margin-bottom: 1rem;
    font-size: $font-size-body-l;
    font-weight: bold;
    text-transform: none;
  }

  .address-buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 0.5rem;
  }

  .address-buttons__edit,
  .address-buttons__primary {
    @extend %link-shop;
  }

  .address-buttons__primary {
    margin-left: 1rem;
  }

  .address-list__other .address-show:not(:last-child) {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $color-border-primary;
  }

  .address-form__heading {
    @extend %text-shop-heading-secondary;
    margin-bottom: 1rem;
  }

  .address-form__item {
    margin-top: 1rem;
  }

  .address-form__item-county {
    display: none;
  }

  .address-form__label {
    margin-bottom: 0;
  }

  .address-form__input,
  .address-form__dropdown {
    @extend %input-shop;
  }

  .address-form__buttons {
    margin-top: 1.5rem;
  }

  .address-form__button-cancel {
    margin-left: 1rem;
  }

  .order-information p {
    margin-bottom: 0;
  }
}
