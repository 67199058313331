@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.ms-nav {
  text-align: left;
  display: block;
  z-index: 50;
  position: absolute;
  top: 41px;
  width: 100%;
  padding: 0;
  background-color: transparentize($color: $color-background-header, $amount: 0.05);
  @include media-breakpoint-up(lg) {
    text-align: right;
    padding: 1.25rem 0 0.7rem;
    position: static;
    width: auto;
    background-color: transparent;
  }
  &__list {
    margin: 0;

    &:first-child {
      padding: 3.95rem 2rem 1.05rem;
      @include media-breakpoint-up(lg) {
        display: inline-flex;
        padding: 0;
      }
      li {
        text-align: right;
        @include media-breakpoint-up(lg) {
          text-align: center;
          position: relative;
        }
        .ms-nav__list__item__button {
          @extend %link-nav-primary;
          @include media-breakpoint-up(lg) {
            padding: 0.37rem 1rem;
            margin-bottom: 0.15rem;
          }
          &.selected {
            color: $color-white;
            font-weight: bold;
          }
        }
      }
    }
    &__item {
      &:last-child {
        ul {
          @include media-breakpoint-up(lg) {
            &::before {
              left: inherit;
              right: 50px;
              transform: none;
            }
          }
        }
      }
      ul {
        @extend %link-nav-secondary-menu;
        background-color: transparent;
        animation: fadeIn 0.5s linear;
        width: max-content;
        border: 0;
        @include media-breakpoint-up(lg) {
          background-color: $color-background-header;
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
        @include media-breakpoint-up(lg) {
          &::before {
            content: "";
            position: absolute;
            margin-top: -26px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border: 7px solid transparent;
            border-bottom-color: #000;
          }
        }

        li {
          padding: 0.2rem 1.5rem 0.4rem;
          width: 100%;
          a {
            @extend %link-nav-secondary;
            @extend .no-underline;

            width: 100%;
            display: block;
            &.selected {
              color: $color-white !important;
            }
          }
        }
      }
      &:last-child {
        ul {
          transform: none;
          right: 0;
          left: inherit;
        }
      }
    }
  }
}

// Mobile Navigation
.nav-open,
.subnav-menu {
  display: block !important;
}
.subnav-menu {
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: all 0.35s ease;
  &.subnav-show {
    transition: all 1s ease;
    visibility: visible;
    max-height: 1000px;
  }
}
@media (max-width: 991px) {
  .mobile-vp {
    .ms-nav-list {
      padding: 2.2rem 2rem;
    }
    .ms-nav__list__item__button {
      padding: 0 0 1rem;
      width: 100%;
      text-align: right;
      line-height: 1.4;
      outline: 0;
    }
  }
  .subnav-menu {
    ul {
      position: static;
      transform: none;
      padding: 0 !important;
      float: right;
      width: 100%;
      li {
        padding: 0 0 1rem !important;
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .subnav-show {
    .ms-nav {
      text-align: left;
      &__list {
        margin: 0;
        &:first-child {
          padding: 4rem 2rem 1rem 2rem;
          li {
            .ms-nav__list__item__button {
              padding: 0.5rem 0;
            }
            &:first-child {
              ul {
                padding: 0;
                li {
                  padding: 0.5rem 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
