$slider-focus-border-thickness: 1px;
$slider-focus-border-style: dashed;
$slider-focus-border-color: $color-white;
$slider-focus-outline-color: $color-black;

.slider__thumb__range:focus {
  + .slider__thumb {
    border: $slider-focus-border-thickness $slider-focus-border-style
      $slider-focus-border-color;
    outline: $slider-focus-border-thickness $slider-focus-border-style
      $slider-focus-outline-color;
  }
}
