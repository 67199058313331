.ms-text-block {
  &.alignment__center {
    text-align: center;
  }
  &.alignment__left {
    text-align: left;
  }
  &.alignment__right {
    text-align: right;
  }

  &.fontsize__small {
    font-size: small;
  }
  &.fontsize__medium {
    font-size: medium;
  }
  &.fontsize__large {
    font-size: large;
  }
  &.fontsize__extralarge {
    font-size: x-large;
  }

  a {
    color: $color-link-secondary;
    text-decoration: underline;
    &:hover,
    &:focus,
    &:active {
      color: $color-link-secondary-hover;
      text-decoration: none;
    }
  }
}
.ms-text-block.content-marketing {
  padding-left: 15px;
  padding-right: 15px;
}
