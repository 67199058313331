$msv-checkout-gift-card-max-flex-basis: 100%;
$msv-checkout-gift-card-margin: 20px;

$msv-checkout-gift-card-input-label-margin-bottom: 12px;

$msv-checkout-gift-card-button-height: 32px;
$msv-checkout-gift-card-button-border-radius: 2px;
$msv-checkout-gift-card-button-margin-left: 20px;

$msv-checkout-gift-card-input-height: 32px;
$msv-checkout-gift-card-input-border-radius: 2px;
$msv-checkout-gift-card-input-padding: 6px 8px;
$msv-checkout-gift-card-input-width: 40%;

$msv-checkout-gift-card-error-max-width: 512px;
$msv-checkout-gift-card-error-padding: 5px;
$msv-checkout-gift-card-error-margin-right: 12px;
$msv-checkout-gift-card-error-margin-bottom: 10px;
$msv-checkout-gift-card-error-icon-margin-right: 8px;

$msv-checkout-gift-card-item-text-width: 60%;

$msv-checkout-gift-card-btn-remove-border: none;
$msv-checkout-gift-card-btn-remove-padding: 0;

$msv-checkout-gift-card-remove-button-margin-left: 10px;

.ms-checkout {
  &-gift-card__form {
    display: flex;
    flex-flow: row wrap;
    .ms-checkout-gift-card__input-fields {
      width: 100%;
    }
  }

  &-gift-card {
    margin-bottom: $msv-checkout-gift-card-margin;
    margin-top: $msv-checkout-gift-card-margin;
    &__title {
      font-weight: 600;
    }
    &__input-label {
      font-weight: 600;
      font-size: $font-size-body-xl;
      line-height: 28px;
      margin-bottom: $msv-checkout-gift-card-input-label-margin-bottom;
      flex-basis: $msv-checkout-gift-card-max-flex-basis;
    }

    &__input-text {
      height: calc(1.5em + 0.75rem + 2px);
      font-weight: 400;
      line-height: 1.5;
      background-clip: padding-box;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      display: block;
      width: 100%;
      @extend %input-shop;
    }

    &__btn-apply {
      @extend %btn-default;
      @extend .btn-secondary;
      margin-top: 1rem;
    }

    &__input-error {
      background-color: $color-link-primary;
      border: 1px solid $color-link-primary;
      max-width: $msv-checkout-gift-card-error-max-width;
      padding: $msv-checkout-gift-card-error-padding;

      margin-right: $msv-checkout-gift-card-error-margin-right;
      margin-bottom: $msv-checkout-gift-card-error-margin-bottom;
      &:before {
        display: none;
        @include msv-icon();
        content: $msv-IncidentTriangle;
        margin-right: $msv-checkout-gift-card-error-icon-margin-right;
      }
      display: block;
      color: $color-white;
    }

    &__item {
      margin-top: 1rem;
    }
    &__item-text {
      padding-right: 0.5rem;
    }

    &__btn-remove {
      display: inline-block;
      @extend .btn-link;
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
      font-size: $font-size-link-l;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
