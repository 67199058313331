h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-family-heading;
  color: $color-text-black;
}

a {
  color: inherit;
  transition: 0.5s all ease;
  text-decoration: underline;
  text-decoration-color: inherit;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}

.no-underline {
  text-decoration: none !important;
}

ol,
ul,
li {
  list-style: none;
}

a:has(h1),
a:has(h2),
a:has(h3),
a:has(h4),
a:has(h5),
a:has(h6),
a:has(.h1),
a:has(.h2),
a:has(.h3),
a:has(.h4),
a:has(.h5),
a:has(.h6) {
  text-decoration: none;
}
