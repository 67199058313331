$wineclub-checkout-input-color: #666;
$wineclub-checkout-input-bg-color: #efefef;
$wineclub-checkout-input-border-color: #706A66;
$wineclub-checkout-input-font-family: $font-family-body;

%wineclub-input {
    min-height: 30px;
    height: calc(1.5em + 0.75rem + 2px);
    width: 100%;
    display: block;
    padding: 0.25rem 0.5rem;
    margin-bottom: 15px;
    font-family: $wineclub-checkout-input-font-family;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: $wineclub-checkout-input-color;
    border: 1px solid $wineclub-checkout-input-border-color;
    background-color: $wineclub-checkout-input-bg-color;
    background-clip: padding-box;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.checkout.wineclub-checkout {
    .checkout-heading>.row>.col-12 {
        padding: 0;

        .content-block-with-title-text {
            width: 100%;
        }
    }

    .ms-checkout {
        padding-left: 0;
        padding-right: 0;
    }

    .ms-checkout__body>.ms-checkout__main {
        margin-top: 2rem;

        @include breakpoint(lg) {
            margin-top: 0;
        }
    }

    .ms-checkout__guided-card:first-of-type .ms-checkout__guided-card-body {
        margin-top: 0;
    }

    .ms-checkout__side-control-first,
    .msc-cart-line__quantity,
    .msc-cart-line__product-variants,
    .ms-checkout__line-items-header {
        display: none;
    }

    .ms-checkout__line-items {
        margin-top: 1rem;
    }

    .ms-checkout__line-items-group-title-multiple-pickup-heading,
    .ms-checkout__line-items-group-title-multiple-pickup-subheading {
        display: inline-block;
    }

    .ms-checkout__line-items-group-title-multiple-pickup-subheading {
        margin-left: 5px;
    }

    .msc-cart-line__product a {
        user-select: none;
        pointer-events: none;
        touch-action: none;
        text-decoration: none;
    }

    .msc-cart-line__product,
    .msc-cart-line__product-price {
        margin-top: 10px;
        display: inline-block;
        width: auto !important;
    }

    .ms-checkout-payment-instrument__error-message {
        margin-left: 0;
    }
}

.checkout-step-club-info {
    &-heading {
        margin-bottom: 20px;
        font-size: 28px;
        text-transform: uppercase;
    }

    input,
    select {
        @extend %wineclub-input;
    }
}

.checkout-club-info {
    &-collector-display-field {

        &-name,
        &-value {
            display: block;
        }

        &-name {
            color: $wineclub-checkout-input-color;
        }

        &-value {
            margin-bottom: 10px;
        }
    }
}