.detailed-order {
  .ms-order-details {
    &__heading {
      @extend %text-shop-heading-primary;
    }
    &__order-information {
      padding-bottom: 1rem;
    }
    &__order-information-channel-reference-id {
      @extend %text-shop-strong;
      display: block;
      margin-bottom: 0.25rem;
      font-weight: normal;
    }
    &__order-information-sales-id,
    &__order-information-created-date,
    &__order-information-count,
    &__order-information-amount {
      @extend %text-shop-strong;
      padding-right: 0.25rem;
      font-weight: normal;
      border-right: 1px solid $color-border-primary;
    }
    &__order-information-created-date,
    &__order-information-count,
    &__order-information-amount {
      padding-left: 0.25rem;
    }
    &__order-information-amount {
      padding-right: 0;
      border: 0;
    }
    &__groups {
      border-top: 1px solid $color-border-primary;
      border-bottom: 1px solid $color-border-primary;
    }
    &__group {
      display: flex;
      flex-wrap: wrap;
    }
    &__sales-lines {
      width: 100%;
      display: grid;
      @include media-breakpoint-up(lg) {
        width: calc(100% - 340px);
      }
      @include media-breakpoint-up(xl) {
        display: flex;
        flex-wrap: wrap;
      }
    }
    &__sales-line {
      padding: 1.25rem 0;
      min-height: 130px;
      @include media-breakpoint-up(xl) {
        max-width: 50%;
        flex: 50%;
      }
      &-btn-buy-it-again {
        @extend %btn-default;
        @extend .btn-secondary;
        position: relative;
        z-index: 1;
        margin-top: 0.3rem;
        margin-left: 60px;
      }
      .msc-cart-line {
        display: flex;
        width: 100%;
        @include media-breakpoint-up(xl) {
          width: auto;
        }
        &__product-image {
          margin-right: 0;
          flex: 0 0 60px;
          max-width: 60px;
          position: relative;
          img {
            max-height: 90px;
            max-width: 50px;
            z-index: 100;
            position: absolute;
            top: 0.3rem;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 0.15rem;
            transition: all 0.3s linear;
            &:hover {
              max-width: 300px;
            }
          }
        }
        &__content {
          display: flex;
          flex: 0 0 88%;
          max-width: 88%;
          flex-direction: column;
          margin-left: 60px;
          @include media-breakpoint-up(md) {
            margin-left: unset;
          }
        }
        &__product-title {
          @extend %link-shop;
          font-weight: bold;
        }
        &-item-product-discount {
          display: none;
        }
        &__quantity {
          margin: 0;
          .quantity-label {
            &::after {
              content: ":";
              display: inline-block;
            }
          }
          .quantity-value {
            margin-left: 5px;
          }
        }
        &__product-price {
          text-align: left;
        }
      }
    }
    .salesLine {
      font-weight: normal;
      line-height: 2;
      font-style: italic;
      color: $color-black;
      .ms-order-details__sales-status {
        margin-left: 60px;
        display: inline-block;
        &::before {
          content: "Status: ";
          font-weight: bold;
          font-style: normal;
          color: $color-text-primary;
        }
      }
    }
    &__group-delivery {
      padding: 1.25rem 1.25rem 1.25rem 0;
      flex: 0 0 100%;
      @include media-breakpoint-up(lg) {
        flex: 0 0 340px;
      }
    }
    &__address {
      margin-top: 6px;
      margin-bottom: 6px;
      &-header {
        display: none;
      }
      &-name,
      &-full-address {
        display: block;
        line-height: 1.5;
      }
    }
    &__order-summary {
      color: $color-black;
      float: none;
      width: 100%;
      margin-top: 1.25rem;
      @include media-breakpoint-up(sm) {
        width: 35.5%;
        float: right;
      }
      &-line-subtotal,
      &-line-shipping,
      &-line-tax-amount,
      &-line-total-amount {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
      }
      &-line-total-amount {
        padding-top: 0.5rem;
        border-top: 1px solid $color-border-primary;
        .ms-order-details__order-summary-label {
          font-weight: bold;
        }
      }
    }
    &__payment-methods {
      color: $color-black;
      float: none;
      width: 100%;
      margin: 1.25rem 0 0 0;
      @include media-breakpoint-up(sm) {
        width: 64.5%;
        float: left;
      }
      &-title {
        font-weight: bold;
        margin-bottom: 4px;
      }
      &-line {
        margin-bottom: 0.5rem;
      }
    }
  }
}
