$msv-checkout-billing-address-margin: 16px;

$msv-checkout-input-margin-left: 0px;
$msv-checkout-input-dimensions: 18px;

$msv-checkout-shipping-address-label-margin-bottom: .5rem;
$msv-checkout-shipping-address-checkbox-margin-left: 8px;

$msv-checkout-address-detail-margin-left: 0px;

.ms-checkout-billing-note {
  font-size: 11px;
  line-height: normal;
}

.ms-checkout-billing-address {
  font-size: $font-size-body-m;
  line-height: 1.5;
  &__heading {
    font-weight: bold;
    margin-bottom: $msv-checkout-billing-address-margin;
  }

  .msc-address-detail {
    margin-left: $msv-checkout-address-detail-margin-left;
  }

  &__shipping-address-label {
    display: flex;
    margin-bottom: $msv-checkout-shipping-address-label-margin-bottom;
  }

  &__input {
    min-width: $msv-checkout-input-dimensions;
    min-height: $msv-checkout-input-dimensions;
    margin-left: $msv-checkout-input-margin-left;
    &-checkbox {
      min-width: auto;
      min-height: auto;
      margin-top: .3rem;
    }
  }

  &__shipping-address-checkbox-text {
    margin-left: $msv-checkout-shipping-address-checkbox-margin-left;
    align-self: center;
    font-size: $font-size-body-m;
    color: $color-dovegray;
  }
}
