.btn {
    border-radius: 0;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    .fa-chevron-right {
        display: none;
    }
}

.btn-link {
    color: $color-link-primary;

    &:hover,
    &:focus,
    &:active {
        color: $color-link-primary-hover;
    }
}

.btn-primary {
    padding: 0.5rem 0.8rem 0.6rem;
    border-color: $color-button-primary-border;
    background-color: $color-button-primary-background;
    color: $color-button-primary-text;
    font-family: $font-family-link;
    font-size: $font-size-link-m;
    letter-spacing: 0.85px;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
        border-color: $color-button-primary-border-hover;
        background-color: $color-button-primary-background-hover;
        color: $color-button-primary-text-hover;
    }
}

.btn-secondary {
    @extend .btn-primary;
    border-color: $color-button-secondary-border;
    background-color: $color-button-secondary-background;
    color: $color-button-secondary-text;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus {
        border-color: $color-button-secondary-border-hover;
        background-color: $color-button-secondary-background-hover;
        color: $color-button-secondary-text-hover;
    }
}

.btn-black {
    @include black-button();
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-secondary.disabled,
.btn-secondary:disabled {
    border-color: $color-button-disabled-border;
    background-color: $color-button-disabled-background;
    color: $color-button-disabled-text;
    cursor: default;
}

.msc-add-to-cart:disabled,
.msc-add-to-cart.disabled {
    line-height: 1;
    cursor: default;
}
