.ms-product-specification{

    div>div {
        border-collapse: separate;
        border-spacing: 0 10px;
    }
    &__heading {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    &__table-row {

        th {
            display: block;
            text-align: left;
            @include font-content(600, 20px, 28px, none);
        }

        td {
            display: block;
            @include font-content-s-height-l();
        }
    }
}